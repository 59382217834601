import m from "mithril"
import grpc_web from "grpc-web"
import { Message } from "google-protobuf"
import { Any } from "google-protobuf/google/protobuf/any_pb"
// Use a direct import here, since importing from /src/components/polythene will
// result in a circular import.
import Snackbar from "/src/components/polythene/Snackbar"
import { translate as t } from "/src/i18n"
import contracts from "@satys/contracts"

const web = grpc_web
const status_code = grpc_web.StatusCode
// This is our custom "status code" that we throw when we cancel a request
const ABORTED_STATUS_CODE = 99

export {
    web,
    status_code,
    ABORTED_STATUS_CODE,
}

type UnkownRpcResponse = grpc_web.UnaryResponse<unknown, unknown>

export function handledError(
    successFunction: (response: UnkownRpcResponse) => unknown,
    redraw = true,
) {
    return (error: grpc_web.RpcError, response: UnkownRpcResponse) => {
        if (error) {
            handleError(error)
        } else {
            successFunction(response)
        }
        if (redraw) {
            m.redraw()
        }
    }
}

export function errorCodeToString(code: number): string {
    return Object.keys(grpc_web.StatusCode).find((key) => grpc_web.StatusCode[key] === code)
}

export function errorToMessage(error: grpc_web.RpcError): string {
    if (grpc_web.StatusCode.CANCELLED) {
        t("cancelled", "grpc", "Sorry, something went wrong during the request")
    } else if (grpc_web.StatusCode.UNKNOWN) {
        t("unkown", "grpc", "Sorry, the server cannot be reached")
    } else if (grpc_web.StatusCode.INVALID_ARGUMENT) {
        t("invalid_argument", "grpc", "The input was invalid")
    } else if (grpc_web.StatusCode.DEADLINE_EXCEEDED) {
        t("deadline_exceeded", "grpc", "Sorry, the server is taking too much time")
    } else if (grpc_web.StatusCode.NOT_FOUND) {
        return error.message
    } else if (grpc_web.StatusCode.ALREADY_EXISTS) {
        return error.message
    } else if (grpc_web.StatusCode.PERMISSION_DENIED) {
        t(
            "permission_denied",
            "grpc",
            "You are not allowed to perform this action. Did you select the correct role?",
        )
    } else if (grpc_web.StatusCode.UNAUTHENTICATED) {
        t("unauthenticated", "grpc", "You need to be logged in to perform this action")
    } else if (grpc_web.StatusCode.RESOURCE_EXHAUSTED) {
        t("resource_exhausted", "grpc", "Sorry, the server is a bit too busy")
    } else if (grpc_web.StatusCode.FAILED_PRECONDITION) {
        return error.message
    } else if (grpc_web.StatusCode.ABORTED) {
        t("aborted", "grpc", "You should retry this at a higher level")
    } else if (grpc_web.StatusCode.OUT_OF_RANGE) {
        t("out_of_range", "grpc", "The provided value is out of range")
    } else if (grpc_web.StatusCode.UNIMPLEMENTED) {
        t("unimplemented", "grpc", "Whoops this hasn't been implemented yet")
    } else if (grpc_web.StatusCode.INTERNAL) {
        t("internal", "grpc", "Sorry, something went wrong during the request")
    } else if (grpc_web.StatusCode.UNAVAILABLE) {
        t("unavailable", "grpc", "Sorry, the service is currently unavailable")
    } else if (grpc_web.StatusCode.DATA_LOSS) {
        t("data_loss", "grpc", "We lost some data during processing, please try again")
    }
    return error.message
}

export function handleError(error: grpc_web.RpcError) {
    if (error?.code !== ABORTED_STATUS_CODE) {
        const codeString = errorCodeToString(error.code)
        console.warn("gRPC call returned:", codeString, error.message, error.stack)

        Snackbar.show({ title: errorToMessage(error) })
        m.redraw()
    }
}

export function difference<T extends Message>(listA: T[], listB: T[]): T[] {
    return listA.filter((itemA: T) => {
        if (listB.length === 0) {
            return true
        }

        for (const itemB of listB) {
            if (Message.equals(itemA, itemB)) {
                return false
            }
            return true
        }
    })
}

export function anyToSatys(any: Any): Message {
    const typeUrl = any.getTypeUrl()
    const bytes = any.getValue()

    const [domain, typeString] = typeUrl.split("/")

    if (domain === "types.satys.cx") {
        // example: ["satys", "domain", "Organisation"]
        const list = typeString.split(".")
        // The whole contracts tree wrapped in "satys"
        let type_ = { satys: contracts }

        for (const step of list) {
            /**
             * first try to find the step object in contracts.
             * If we can't find one, try with the suffix "_pb".
             *
             * We do this since for example "domain" is referred to
             * as "domain_pb" in the contracts object.
             */
            type_ = type_[step] || type_[step + "_pb"]
        }
        // Cast to any, since we don't know which message it's gonna be and jspb.Message
        // doesn't include an interface for deserializeBinary
        return (type_ as any).deserializeBinary(bytes)  // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

export function domainToAny(domain: Message) {
    const type = domain.constructor.toString().match(/proto\.([.\w]+)\.repeatedFields_/)[1]
    const any_pb = new Any()
    any_pb.setValue(domain.serializeBinary())
    any_pb.setTypeUrl(`types.satys.cx/${type}`)
    return any_pb
}

// Backwards compatability
export const handled_error = handledError
export const err_to_msg = errorToMessage
export const err_code_to_str = errorCodeToString
export const handle_err = handleError
export const any_pb_to_satys = anyToSatys
export const any_to_satys = anyToSatys
