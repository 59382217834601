import { Message } from "google-protobuf"
import { CacheKey, GrpcCache } from "/src/services/grpcCache"
import { executeGrpcCall } from "/src/services/grpcRequestQueue"
import { GrpcCallResponse, MethodKeys } from "/src/services/IGrpcRequestQueue"
import { DatanalysisAPIPromiseClient } from "@satys/contracts/satys/datanalysis/datanalysis_grpc_web_pb"

export default abstract class BaseService {
    abstract readonly stub: DatanalysisAPIPromiseClient
    abstract readonly cache: GrpcCache

    execute<T extends Message, U extends Message>(
        method: MethodKeys<typeof this.stub>,
        request: T,
        metadata: Record<string, string> = {},
    ): GrpcCallResponse<U> & { isCached: boolean } {
        const abortController = new AbortController()
        const cacheKey: CacheKey = { method, request, metadata }

        const cachedPromiseResponse = this.cache.get(cacheKey)
        if (cachedPromiseResponse) {
            return { promise: cachedPromiseResponse, cancel: abortController.abort, isCached: true }
        }

        const { promise, cancel } = executeGrpcCall<T, U>({
            client: this.stub,
            method,
            request,
            metadata,
            controller: abortController,
        })

        this.cache.set(cacheKey, promise)

        return { promise, cancel, isCached: false }
    }
}
