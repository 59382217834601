import { DatanalysisAPIPromiseClient } from "@satys/contracts/satys/datanalysis/datanalysis_grpc_web_pb"
import { GetOrganisationWithDescendantsRequest, GetOrganisationWithDescendantsResponse, GetUserRolesRequest, GetUserRolesResponse } from "@satys/contracts/satys/datanalysis/datanalysis_pb"
import { Organisation } from "@satys/contracts/satys/domain/domain_pb"
import BaseService from "/src/services/baseService"
import { GrpcCache } from "/src/services/grpcCache"
import { datanalysis_promise_stub } from "/src/stubs"

class UserService extends BaseService {
    stub: DatanalysisAPIPromiseClient
    cache: GrpcCache

    constructor(cache = new GrpcCache(), stub = datanalysis_promise_stub) {
        super()
        this.cache = cache
        this.stub = stub
    }

    getUserRoles({
        request,
        depth = 0,
        metadata,
    }: {
        request?: GetUserRolesRequest
        depth?: number
        metadata?: Record<string, string>
    } = {}) {
        if (!request) {
            request = new GetUserRolesRequest()
            request.setDepth(depth)
        }

        return this.execute<GetUserRolesRequest, GetUserRolesResponse>(
            "get_user_roles",
            request,
            metadata,
        )
    }

    getOrganisationWithDescendants({
        request = new GetOrganisationWithDescendantsRequest(),
        organisation,
        metadata,
    }: {
        request?: GetOrganisationWithDescendantsRequest,
        organisation?: Organisation,
        metadata?: Record<string, string>,
    } = {}) {
        if (organisation) {
            request.setOrganisation(organisation)
        }

        return this.execute<GetOrganisationWithDescendantsRequest, GetOrganisationWithDescendantsResponse>(
            "get_organisation_with_descendants",
            request,
            metadata,
        )
    }
}

export const userService = new UserService()
