import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

import { State } from "/src/state"
import { Actions } from "/src/actions"
import { currentRoute } from "/src/router"
import { getRole, getUserRoles } from "/src/auth"
import { userService } from "/src/services/userService"
import { Organisation, OrganisationWithDescendants, Role } from "@satys/contracts/satys/domain/domain_pb"

import * as styles from  "./index.module.sass"

interface Attrs {
    state: State
    actions: (state: State) => Actions
}

export default class BreadCrumbs extends MithrilTsx<Attrs> {
    actions: Actions
    roles: Role[] = []
    showDropdown = false
    organisationTree: Organisation[] = []
    hoveringOrganisation: OrganisationWithDescendants | null = null
    organisationsWithDescendants: OrganisationWithDescendants[] = []

    async oninit(vnode: this["Vnode"]) {
        this.actions = vnode.attrs.actions(vnode.attrs.state)

        await Promise.all([this.getUserRoles(), this.getOrganisationTree()])
        m.redraw()
    }

    oncreate() {
        window.addEventListener("click", this.closeDropdown.bind(this))
    }

    onremove() {
        window.removeEventListener("click", this.closeDropdown.bind(this))
    }

    closeDropdown(e: Event) {
        const element = e.target as HTMLElement
        const dropdown = document.querySelector(`.${styles.breadcrumbs} .dropdown-wrapper`)

        if (!dropdown) {
            // the component is not mounted properly (yet)
            return
        }

        if (!this.showDropdown && !dropdown.contains(element)) {
            this.showDropdown = dropdown.parentNode.contains(element)
        } else {
            this.showDropdown = false
        }

        m.redraw()
    }

    async getUserRoles() {
        this.roles = await getUserRoles()
    }

    async getOrganisationTree() {
        const topLevelRolesRequestState = userService.getUserRoles({ depth: 1 })
        const topLevelRolesResponse = await topLevelRolesRequestState.promise
        const topLevelRoles = topLevelRolesResponse.getRolesList()

        for (const role of topLevelRoles) {
            const requestState = userService.getOrganisationWithDescendants({
                metadata: {
                    role: role.getName(),
                    organisation: role.getOrganisation().getDomain(),
                },
            })
            const response = await requestState.promise
            this.organisationsWithDescendants.push(response.getOrganisation())
        }
    }

    view() {
        const currentOrganisation = getRole().getOrganisation()
        const sisterOrganisations = getSisterOrganisations(this.organisationsWithDescendants, currentOrganisation)

        return (
            <div className={styles.breadcrumbs + " breadcrumbs"}>
                <div className="crumbs">
                    <a
                        href={m.route.prefix + m.buildPathname("/select_role", { return_to: currentRoute?.path })}
                        class="crumb-item crumb-item--margin-top"
                    >
                        { m.trust(require("/src/assets/icons/arrow_right_left.svg")) }
                    </a>

                    <i className="material-icons seperator">navigate_next</i>

                    { getAnscestorOrganisations(this.organisationsWithDescendants, currentOrganisation).map(o => {
                        const params = Object.assign({}, m.route.param(), { organisation_domain: o.getDomain() })
                        const path = m.route.prefix + m.buildPathname(currentRoute?.path, params)

                        return (
                            <>
                                { this.roles.some(r => r.getOrganisation().getDomain() === o.getDomain()) ? (
                                    <a className="parent crumb-item" title={o.getName()} href={path}>
                                        { o.getName() }
                                    </a>
                                ) : (
                                    <span>
                                        { o.getName() }
                                    </span>
                                ) }

                                <i className="material-icons seperator">navigate_next</i>
                            </>
                        )
                    }) }

                    <div className="relative">
                        <span className="current">
                            <span className="crumb-item">{ currentOrganisation.getName() }</span>

                            { sisterOrganisations.length ? (
                                <i className="material-icons v-btm f-inherit noprint">expand_more</i>
                            ) : <></> }
                        </span>

                        { sisterOrganisations.length ? (
                            <div className={`dropdown-wrapper ${this.showDropdown ? "active" : ""}`} onmouseleave={() => this.hoveringOrganisation = null}>
                                <div className="dropdown">
                                    { sisterOrganisations
                                        .sort((a, b) => a.getOrganisation().getName().localeCompare(b.getOrganisation().getName()))
                                        .map((sisterOrganisationWithDescendants, i) => {
                                            const sisterOrganisation = sisterOrganisationWithDescendants.getOrganisation()
                                            const isActive = sisterOrganisation.getDomain() === currentOrganisation.getDomain()

                                            const params = Object.assign({}, m.route.param(), { organisation_domain: sisterOrganisation.getDomain() })
                                            const path = m.route.prefix + m.buildPathname(currentRoute?.path, params)

                                            const hasSubOrganisations = Boolean(sisterOrganisationWithDescendants.getDescendantsList().length)

                                            return (
                                                <a
                                                    key={i}
                                                    className={`dropdown-item ${isActive ? "active" : ""}`}
                                                    onmouseenter={(event: MouseEvent) =>  {
                                                        if (!hasSubOrganisations) {
                                                            return
                                                        }

                                                        this.hoveringOrganisation = sisterOrganisationWithDescendants
                                                        const rect = (event.currentTarget as HTMLElement).getBoundingClientRect()
                                                        const subDropdown = document.querySelector<HTMLElement>(".subdropdown")
                                                        subDropdown.style.top = `${rect.top}px`
                                                    }}
                                                    title={sisterOrganisation.getName()}
                                                    href={path}
                                                >
                                                    <span>{ sisterOrganisation.getName() }</span>

                                                    { hasSubOrganisations ? (
                                                        m.trust(require("/src/assets/icons/chevron_right.svg"))
                                                    ) : <></> }
                                                </a>
                                            )
                                        }) }
                                </div>

                                <div className={`subdropdown ${this.hoveringOrganisation?.getDescendantsList().length ? "active" : ""}`}>
                                    <div className="dropdown">
                                        { this.hoveringOrganisation?.getDescendantsList()
                                            .sort((a, b) => a.getOrganisation().getName().localeCompare(b.getOrganisation().getName()))
                                            .map((o, i) =>  {
                                                const params = Object.assign({}, m.route.param(), { organisation_domain: o.getOrganisation().getDomain() })
                                                const path = m.route.prefix + m.buildPathname(currentRoute?.path, params)

                                                return (
                                                    <a
                                                        key={i}
                                                        className="dropdown-item"
                                                        title={o.getOrganisation().getName()}
                                                        href={path}
                                                    >
                                                        { o.getOrganisation().getName() }
                                                    </a>
                                                )
                                            }) }
                                    </div>
                                </div>
                            </div>
                        ) : <></> }
                    </div>
                </div>
            </div>
        )
    }
}

function getAnscestorOrganisations(organisationsWithDescendants: OrganisationWithDescendants[], currentOrganisation: Organisation) {
    function findParents(node: OrganisationWithDescendants, path: Organisation[]) {
        if (node.getOrganisation().getDomain() === currentOrganisation.getDomain()) {
            return true
        }

        for (const child of node.getDescendantsList()) {
            if (findParents(child, path)) {
                if (!path.includes(node.getOrganisation())) {
                    path.push(node.getOrganisation())
                }
                return true
            }
        }
        return false
    }

    const ancestors = []
    for (const organisationWithDescendants of organisationsWithDescendants) {
        findParents(organisationWithDescendants, ancestors)
    }
    return ancestors.reverse()
}

function getSisterOrganisations(organisationsWithDescendants: OrganisationWithDescendants[], currentOrganisation: Organisation) {
    const satysOrg = new Organisation()
    satysOrg.setDomain("cx_satys")
    const parent = getAnscestorOrganisations(organisationsWithDescendants, currentOrganisation)?.at(-1) || satysOrg

    for (const organisationWithDescendants of organisationsWithDescendants) {
        const found = getSubOrganisations(organisationWithDescendants, parent)
        if (found) {
            return found.getDescendantsList()
        }
    }

    return []
}

function getSubOrganisations(organisationWithDescendants: OrganisationWithDescendants, organisation: Organisation): OrganisationWithDescendants | null {
    if (organisationWithDescendants.getOrganisation().getDomain() === organisation.getDomain()) {
        return organisationWithDescendants
    }

    for (const child of organisationWithDescendants.getDescendantsList()) {
        const found = getSubOrganisations(child, organisation)
        if (found) {
            return found
        }
    }

    return null
}
