import "core-js/stable"
import "regenerator-runtime/runtime"

import "./modules.d.ts"

import m from "mithril"
import state from "/src/state"
import MithrilTsx from "/src//mithril-tsx"
import Bumper from "/src/components/bumper"
import Spinner from "/src/components/polythene/MaterialDesignSpinner"

import { getCurrentPathFromWindow, useRouter } from "./router"
import { LoginRedirect } from "/src/auth/types"
import { translate as t, getLocale, setLocale } from "/src/i18n"
import { Profile } from "@satys/contracts/satys/domain/domain_pb"
import { initAuthClient, initAuth, getUser, verifyInit as authVerifyInit, signUp } from "/src/auth"
import { tawkto } from "/src/extensions"

const actions = require("/src/actions/index.ls")

const {
    Button,
    PrimaryButton,
    Dialog,
    Notification,
    Snackbar,
} = require("/src/components/polythene")
const meta = require("/src/htmlmeta.ls")

import "/src/css/index.sass"
import "/src/css/_modal.sass"

meta.title.base = "SatysApp"
if (process.env.NODE_ENV !== "production") {
    meta.title.base += `(${process.env.NODE_ENV})`
}
meta.title.separator = "-"

const BUMPER_DURATION_MS = 1800
const BUMPER_OVERLAP_MS = 200

class Main extends MithrilTsx<unknown> {
    showBumper = true
    loading = true

    setPageLoadTrue = () => this.loading = true
    setPageLoadFalse = () => this.loading = false
    updatedStorage = () => {if (getUser()) {m.redraw()}}

    oninit() {
        const { initRouter, initRouterError } = useRouter()

        const currentPath = getCurrentPathFromWindow()
        if (currentPath.path === "/register") {
            const emailAddress = currentPath.params.get("email") ?? ""
            initAuthClient().then(signUp.bind(null, emailAddress))
        }

        initAuth(actions).then(async () => {
            // Make sure it redraws before mounting so the body is available
            m.redraw.sync()
            initRouter(document.getElementById("body"))
            // Auth is initiated and the routes are defined, now mount the actual application.
            this.loading = false
            m.redraw()
        }).catch(async (e) => {
            if (e instanceof LoginRedirect) {
                console.log("Redirecting to login")
                // Do nothing, since it will redirect anyway
                return
            }
            // Make sure it redraws before mounting so the body is available
            m.redraw.sync()
            initRouterError(e, document.getElementById("body"))
            this.loading = false
            m.redraw()
        }).finally(async () => {
            setLocale(getLocale())

            window.addEventListener("storage", this.updatedStorage)
            window.addEventListener("page-loading", this.setPageLoadTrue)
            window.addEventListener("page-loaded", this.setPageLoadFalse)

            window.onresize = m.redraw

            if (await getUser()) {
                await actions(state).auth.get_profile({
                    success_cb: (profile: Profile) => {
                        const lang = profile.getLocale()
                        setLocale(new Intl.Locale(lang))
                    },
                })
                tawkto.set_user(state.user)
            }
        })
    }

    oncreate() {
        setTimeout(() => {
            // Not using vnode.dom since it only contains the first node
            const classList = document.getElementById("main").classList
            classList.add("fade-in")
            classList.remove("dn")
            setTimeout(() => {
                this.showBumper = false
                m.redraw()
            }, BUMPER_OVERLAP_MS)
        }, BUMPER_DURATION_MS - BUMPER_OVERLAP_MS)
    }

    onremove() {
        window.removeEventListener("storage", this.updatedStorage)
        window.removeEventListener("page-loading", this.setPageLoadTrue)
        window.removeEventListener("page-loaded", this.setPageLoadFalse)
    }

    /**
     * Check whether auth.verifyInit is succesful.
     */
    authReady() {
        try {
            authVerifyInit()
            this.loading = false
            return true
        } catch {
            return false
        }
    }

    view() {
        return (
            <>
                { state.printElement ? (
                    <div id="print" style="width: 1024px; position: fixed; top: 0; left: 0; z-index: -9999;">
                        { state.printElement }
                    </div>
                ) : <></> }
                { this.showBumper ? <Bumper absoluteCenter /> : <></> }
                <div id="main" class="mid-gray v-fill-screen relative dn">
                    { this.loading ? <Spinner /> : <></> }
                    { this.authReady() ? (
                        <>
                            <Dialog
                                footerButtons={(
                                    <PrimaryButton
                                        label={t("ok")}
                                        events={{ onclick: Dialog.hide }}
                                    />
                                )}
                            />
                            <Notification />
                            <Snackbar
                                id="snackbar"
                                timeout={3}
                                action={(
                                    <Button
                                        label="x"
                                        events={{ onclick: Snackbar.hide }}
                                    />
                                )}
                            />

                            { this.loading && (
                                <div id="modal">
                                    <div class="content">
                                        <Spinner />
                                    </div>
                                </div>
                            ) }
                            <div id="body" />
                        </>
                    ) : <></> }
                </div>
            </>
        )
    }
}

m.mount(document.body, Main)

if (process.env.NODE_ENV !== "production") {
    console.debug("Running with NODE_ENV=" + process.env.NODE_ENV)
}
