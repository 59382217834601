import { Auth0Client, AuthorizationParams, createAuth0Client } from "@auth0/auth0-spa-js"
import {
    GetRoleTasksRequest, GetRoleTasksResponse, GetUserRolesResponse,
} from "@satys/contracts/satys/datanalysis/datanalysis_pb"
import { Organisation, Process, Product, Role, Task, User } from "@satys/contracts/satys/domain/domain_pb"
import { RpcError, StatusCode } from "grpc-web"
import { AuthMetadata, AuthState, AuthStatus, LoginAppState, LoginRedirect, Token } from "./types"
import { Actions } from "/src/actions"
import { analytics } from "/src/extensions"
import { userService } from "/src/services/userService"
import state, { State } from "/src/state"

let auth0: Auth0Client
let authInitialized = false

// Local (and global) auth state. Should never directly be accessed.
const authState: AuthState = {
    status: AuthStatus.UNAUTHENTICATED,
    roles: [],
    roleTasks: [],
}

// Local tokenCache which can be used synchronously
let _syncTokenCache: Token = {}

let _actions: Actions

let profilePictureUrl = null

/**
 * Throw a developer-f  riendly error when initAuth isn't properly called.
 */
export function verifyInit() {
    if (isLoggingIn) { throw "You can't call this function while the login redirect is running!" }
    if (!auth0) { throw "The auth0 client isn't available here (yet)." }
    if (!authInitialized) { throw "You must call and await initAuth first!" }
}

/**
 * Initialize auth0 client with environment variables.
 */
export async function initAuthClient() {
    auth0 = await createAuth0Client({
        domain: process.env.AUTH_DOMAIN,
        clientId: process.env.AUTH_CLIENT_ID,
        cookieDomain: process.env.AUTH_COOKIE_DOMAIN,
        useRefreshTokens: true,
        authorizationParams: {
            audience: process.env.AUTH_AUDIENCE,
        },
    })
}

/**
 * Initialize the auth module.
 */
export async function initAuth(actions: (state: State) => Actions) {
    _actions = actions(state)
    await initAuthClient()
    let target: string | undefined
    try {
        target = await handleLoginCallback()
    } catch {
        // Probably the state isn't valid anymore
        await login()
    }
    // Set it after a possible login to prevent race conditions with `verifyInit`
    authInitialized = true

    // Get token, since ontherwise somehow everything breaks
    await getToken()

    // Load role from localStorage
    getRole()

    // Just make sure it's set before any synchronous calls are executed
    await getAuthStatus()

    if (target) {
        window.history.replaceState({}, document.title, decodeURI(target))
    }
}

/**
 * Handle redirect from login.
 */
async function handleLoginCallback() {
    const query = window.location.search
    if (query.includes("code=") && query.includes("state=")) {
        const { appState: { target } } = await auth0.handleRedirectCallback<LoginAppState>()
        return target
    }
}

let getUserRolesPromise: Promise<GetUserRolesResponse>
/**
 * Get the current user's roles from the Satys domain.
 */
export async function getUserRoles() {
    if (!authState.roles.length && !getUserRolesPromise) {
        getUserRolesPromise = userService.getUserRoles().promise
    }
    if (getUserRolesPromise) {
        try {
            const resp = await getUserRolesPromise
            authState.roles = resp.getRolesList()
        } finally {
            // Make sure we unset this, so it will properly redo the request when required.
            getUserRolesPromise = undefined
        }
    }
    return authState.roles
}

/**
 * Set the current user's role.
 */
export function setRole(role: Role) {
    authState.role = role.clone()
    // Save in localstorage so we can use this across sessions
    localStorage.setItem("role", [role.getOrganisation().getDomain(), role.getName()].join("."))

    // Record the change as a Google Analytics event
    analytics.event("select_role", {
        "event_category": role.getOrganisation().getDomain(),
        "event_label": role.getOrganisation().getName(),
        "value": role.getName(),
    })
    analytics.identity(
        { "email_domain": authState.user.getEmailAddress().split("@")[1] },
    )
}

let getRoleTasksPromise: Promise<GetRoleTasksResponse>
let getRoleTasksRole: Role
/**
 * Get all tasks which the current role is allowed to execute.
 */
export async function getRoleTasks() {
    const [newRole, oldRole] = [getRole(), getRoleTasksRole]
    const [oldDomain, newDomain] = [oldRole, newRole].map(r => r?.getOrganisation().getDomain())
    const rolesEqual = newDomain === oldDomain && newRole?.getName() === oldRole?.getName()

    if (!newRole) {
        authState.roleTasks = []
        getRoleTasksPromise = undefined
    } else if (!rolesEqual) {
        getRoleTasksRole = newRole
        const { promise } = _actions.dashboard.aio_get_role_tasks<GetRoleTasksRequest, GetRoleTasksResponse>()
        getRoleTasksPromise = promise
    }

    if (getRoleTasksPromise) {
        try {
            const resp = await getRoleTasksPromise
            authState.roleTasks = resp.getTasksList()
        } finally {
            getRoleTasksPromise = undefined
        }
    }

    return authState.roleTasks
}

/**
 * Get the access token. It is cached and a new one is automatically fetched
 * when there's less than 60 seconds left for the cached token.
 */
export async function getToken(opts?: { forceRefresh: false }) {
    verifyInit()
    try {
        // auth0's getTokenSilently already caches the token and will fetch a new
        // one if there's less than 60 seconds left.
        const { expires_in: _expiresIn, ...token } = await auth0.getTokenSilently({
            detailedResponse: true,
            cacheMode: opts?.forceRefresh ? "off" : "on",
        })
        _syncTokenCache = token

        // If no token provided, throw an error so login is called.
        if (!token.access_token) {
            throw Error("No access token")
        }

        return <Token>token
    } catch (error) {
        await login()
    }
}

let isLoggingIn = false

/**
 * Login (redirects).
 */
export async function login(opts?: AuthorizationParams & { target?: string }) {
    if (isLoggingIn) {
        throw new LoginRedirect()
    }

    function getCallbackURI() {
        const target = window.location.pathname + window.location.hash
        if (target.includes("register") || target.includes("login")) {
            return "/"
        }
        return target
    }

    isLoggingIn = true
    await auth0.loginWithRedirect<LoginAppState>({
        appState: {
            target: opts?.target || getCallbackURI(),
        },
        authorizationParams: {
            ...opts,
            redirect_uri: window.location.origin,
        },
    })
    throw new LoginRedirect()
}

/**
 * Redirects to sign up page.
 */
export async function signUp(emailAddress: string = "") {
    return await login({ screen_hint: "signup", login_hint: emailAddress, target: "/" })
}

/**
 * Logout (redirects).
 */
export async function logout() {
    verifyInit()
    localStorage.removeItem("role")
    return await auth0.logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    })
}

/**
 * Get the current user.
 */
export async function getUser() {
    verifyInit()
    if (!authState.user) {
        const auth0User = await auth0.getUser()
        if (!auth0User) {
            return
        }
        const user = new User()
        user.setEmailAddress(auth0User.email)
        authState.user = user
        profilePictureUrl = auth0User.picture
    }
    return authState.user
}

/**
 * Get the profile picture of the current user.
 */
export async function getProfilePictureUrl() {
    verifyInit()

    if (!authState.user) {
        const auth0User = await auth0.getUser()
        profilePictureUrl = auth0User.picture
    }

    return profilePictureUrl
}

/**
 * Get the current role fom localStorage if any.
 */
export function getRole(): Role | undefined {
    if (!authState.role) {
        // Try to get current role from localstorage
        const roleString = localStorage.getItem("role")
        if (!roleString) {
            return
        }
        const [orgDomain, roleName] = roleString.split(".", 2)

        const organisation = new Organisation()
        organisation.setDomain(orgDomain)

        authState.role = authState.roles
            .find(r => r.getOrganisation().getDomain() === orgDomain && r.getName() === roleName)
    }

    return authState.role
}

/**
 * Smartly get authorization headers and refresh token when expired.
 */
export async function getAuthMetadata() {
    const token = await getToken()
    return _getAuthMetadata(token, getRole())
}

/**
 * Get authorization headers based on loaded state.
 */
export function getAuthMetadataSync() {
    return _getAuthMetadata(_syncTokenCache, getRole())
}

function _getAuthMetadata(token?: Token, role?: Role) {
    const metadata: AuthMetadata = {}
    if (token?.access_token) {
        metadata.authorization = `Bearer ${token.access_token}`
    }
    if (role) {
        metadata.role = role.getName()
        metadata.organisation = role.getOrganisation().getDomain()
    }
    return metadata
}

/**
 * Check how the current user is authenticated.
 */
export async function getAuthStatus(): Promise<AuthStatus> {
    verifyInit()
    const auth0User = await auth0.getUser()
    if (!auth0User) {
        authState.status = AuthStatus.UNAUTHENTICATED
        return authState.status
    }
    if (!auth0User.email_verified) {
        authState.status = AuthStatus.NOT_VERIFIED
        return authState.status
    }
    try {
        await getUserRoles()
        authState.status = AuthStatus.HAS_ROLES
    } catch (e) {
        if (e instanceof RpcError) {
            const failStatuses = [StatusCode.UNKNOWN, StatusCode.UNAVAILABLE, StatusCode.INTERNAL]
            if (failStatuses.includes(e.code)) {
                throw e
            } else {
                authState.status = AuthStatus.NO_ROLES
                return authState.status
            }
        }
        throw e
    }
    if (getRole()) {
        authState.status = AuthStatus.ACTIVE_ROLE
        return authState.status
    }
    return authState.status
}

/**
 * Convert given task into a "product.process.task" string.
 */
export function taskToString(task: Task) {
    const process = task.getProcess()
    const product = process.getProduct()
    return `${product.getName()}.${process.getName()}.${task.getName()}`.toLowerCase()
}

/**
 * Get domain.Task based on view path (within the "views" folder)
 */
export function getTaskByView(path: string) {
    return taskFromString(path.split("views").pop())
}

/**
 * Convert a product.process.task (or product/process/task) string
 * into a domain.Task object.
 */
export function taskFromString(taskString: string) {
    const [prodName, procName, taskName] = taskString.split(/\.|\//, 3).filter(Boolean)

    const product = new Product()
    product.setName(prodName)

    const process = new Process()
    process.setName(procName)
    process.setProduct(product)

    const task = new Task()
    task.setName(taskName)
    task.setProcess(process)

    return task
}

/**
 * Check whether current role is authorized for given task.
 */
export async function isAuthorizedFor(task: Task | string) {
    try {
        const roleTasks = await getRoleTasks()
        return _isAuthorizedFor(roleTasks, task)
    } catch (e) {
        console.warn(`Failed to check authorization: ${e}`)
        return false
    }
}

function _isAuthorizedFor(roleTasks: Task[] = [], task: Task | string) {
    if (task instanceof Task) {
        task = taskToString(task)
    }
    return roleTasks.some(_task => taskToString(_task) === task)
}
