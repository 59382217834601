@use '/src/css/variables' as *
@use 'sass:color'

.sidebar_wrapper :global
    width: 100%
    max-width: 18rem

    .sidebar
        position: sticky
        top: 0
        left: 0
        bottom: 0
        height: 100vh
        width: 100%
        background-color: white
        border-right: 1px solid var(--satys-bg-color)
        filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.10))
        will-change: filter // To get safari to show the drop-shadow
        z-index: 201

        &__brand
            display: flex
            align-items: center
            justify-content: between
            padding: 2rem

        &__image
            height: auto
            // to match as much as possible with SatysManage
            margin: 0.5rem
            width: 8.5rem

        &__link
            position: relative
            display: flex
            align-items: center
            justify-content: space-between
            text-decoration: none
            cursor: pointer
            font-weight: 500
            font-size: 1rem
            margin: 1rem
            color: var(--satys-text-color)

            & svg
                width: 20px
                margin-right: 15px

            & > span
                display: flex
                align-items: center
                padding: 1rem

            &::after
                position: absolute
                left: 0
                top: 0
                height: 100%
                background: transparent
                width: 2px
                content: ""

            &.active, &:hover
                color: var(--satys-text-color)
                border-radius: 5px
                background-color: var(--satys-bg-color)

                svg
                    color: var(--satys-accent)

                &::after
                    position: absolute
                    left: -1rem
                    top: 0
                    height: -100%
                    width: 2px
                    content: ""
                    background-color: var(--satys-accent)

    .spacer
        margin: 2rem 0
        height: 2px
        background-color: var(--satys-bg-color)


    .sidebar__footer
        position: relative
        padding: 0.5rem
        width: 100%
        max-width: 100%

        &-item
            display: flex
            align-items: center
            padding: 1rem
            border-radius: 5px
            text-decoration: none
            color: var(--satys-text-color)
            cursor: pointer

            &:hover
                background-color: var(--satys-bg-color)

            svg
                width: 1rem
                color: #aeaeae

        .profile
            width: 100%
            cursor: pointer
            position: relative
            padding: 1rem
            border-radius: 5px

            .details
                display: grid
                grid-template-columns: 1fr 40px
                align-items: center
                max-width: 100%
                width: 100%

            & > span
                display: block
                color: $satys-accent
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

            .avatar
                display: flex
                width: 2rem
                height: 2rem
                border: 1px solid var(--satys-text-color-light)
                border-radius: 50%
                margin-right: 10px
                color: var(--satys-text-color-light)

            .user, .role
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

            .user
                color: $satys-accent

            .role
                color: silver
                font-size: .875rem
                font-style: italic

            .logout
                display: flex
                align-items: center
                justify-content: center
                height: 40px
                width: 40px
                cursor: pointer

                svg
                    width: 1.5rem
                    color: #aeaeae

@media screen and (max-width: 768px)
    .sidebar
        max-width: 14rem
