import m from "mithril"
import Header, { Attrs as HeaderAttrs } from "./header"
import Overlay, { Attrs as OverlayAttrs } from "./overlay"
import MithrilTsx from "/src/mithril-tsx"
import FeatureNotice from "/src/components/FeatureNotice"
import InsightFeedback from "/src/components/insight_feedback"
import { Snackbar } from "/src/components/polythene"

import state from "/src/state"
import { until } from "/src/utils/wait"
import { getNamespace } from "/src/utils"
import { translate as t } from "/src/i18n"
import { analytics } from "/src/extensions"
import { reloadCssVariables } from "/src/utils/colors"
import clone from "/src/utils/clone"
import { convertHtmlToImageAndDownload } from "/src/utils/UI/downloadHtmlAsImage"

import * as styles from "./index.module.sass"

const ns = getNamespace(__filename)

const LOADING_CLASSES = [".spinner", ".absolute-spinner", ".skeleton", ".inview-loader"]

/**
 * Returns false if any of the elements contains a loading item.
 */
export function isLoaded(elements: HTMLElement[]) {
    return !elements.some(e => e.querySelector(LOADING_CLASSES.join(", ")))
}

export interface Attrs {
    header: HeaderAttrs
    type: string
    colspan?: number
    overlay?: OverlayAttrs
    grid?: {
        [key: string]: string
    }
    featureNotice?: string
    onclick?: () => void
    filters?: JSX.Element
}

export default class Card extends MithrilTsx<Attrs> {
    showFilters = false
    hasFilters = false
    type: string
    mouseEntered: () => void
    mouseClicked: () => void
    mouseLeft: () => void
    mouseEnteredAt: number
    clicks = 0
    cardElement: HTMLElement

    oninit(vnode: this["Vnode"]) {
        this.type = vnode.attrs.type
    }

    oncreate(vnode: this["VnodeDOM"]) {
        this.cardElement = vnode.dom.querySelector(".card")

        if (vnode.attrs.filters) {
            this.hasFilters = true
        }

        this.mouseClicked = () => this.clicks++

        this.mouseEntered = () => {
            this.mouseEnteredAt = new Date().getTime()
            // Start listening for engagement clicks as well
            this.cardElement.addEventListener("click", this.mouseClicked)
        }

        this.mouseLeft = () => {
            const timeElapsed = new Date().getTime() - this.mouseEnteredAt
            if (timeElapsed > 1500 || this.clicks > 0) {
                analytics.event("user_engagement_card", {
                    card_type: this.type,
                    time_hovered: timeElapsed,
                    clicks: this.clicks,
                })
                // Reset the counters
                this.mouseEnteredAt = undefined
                this.clicks = 0
            }
            // Remove the clicklistener to prevent dupes
            this.cardElement.removeEventListener("click", this.mouseClicked)
        }

        this.cardElement.addEventListener("mouseenter", this.mouseEntered)
        this.cardElement.addEventListener("mouseleave", this.mouseLeft)

        window.addEventListener("click", this._hideFiltersIfClickOutside.bind(this, vnode))
    }

    onremove(vnode: this["VnodeDOM"]) {
        this.cardElement.removeEventListener("mouseenter", this.mouseEntered)
        this.cardElement.removeEventListener("mouseleave", this.mouseLeft)
        this.cardElement.removeEventListener("click", this.mouseClicked)
        window.removeEventListener("click", this._hideFiltersIfClickOutside.bind(this, vnode))
    }

    onupdate(vnode: this["VnodeDOM"]) {
        if (vnode.attrs.filters) {
            this.hasFilters = true
        }
    }

    async downloadCardAsImage(vnode: this["VnodeDOM"]) {
        Snackbar.show({
            title: t("downloadingCard", ns),
            timeout: 0,
        })
        this._removeAnimations()

        state.printElement = clone(this).view(vnode)
        m.redraw.sync()

        const printElement = document.getElementById("print")
        const printCard = printElement.querySelector<HTMLElement>(".card")

        printCard.classList.add("save-as")
        await until(() => isLoaded([printCard]))

        try {
            await convertHtmlToImageAndDownload(printElement, `satys-${this.type}-${new Date().toISOString()}`, "jpeg")
            analytics.event("downloaded_card_as_image", { card_type: this.type })
            Snackbar.hide()
            Snackbar.show({
                title: t("downloadCardSuccess", ns),
            })
        } catch (e) {
            Snackbar.hide()
            console.error(e)
            Snackbar.show({
                title: t("downloadCardError", ns),
            })
        } finally {
            state.printElement = undefined
            m.redraw.sync()
        }
    }

    toggleFilters() {
        if (this.hasFilters) {
            this.showFilters ? this._hideFilters() : this._showFilters()
        }
    }

    private _hideFiltersIfClickOutside(vnode: this["VnodeDOM"], e: MouseEvent) {
        if (this.hasFilters && this.showFilters) {
            const target = e.target as HTMLElement
            const cardFilterOverlay = vnode.dom.querySelector<HTMLElement>(".card-filters-overlay")

            if (target.contains(cardFilterOverlay)) {
                this._hideFilters()
            }
        }
    }

    private _removeAnimations() {
        document.documentElement.style.setProperty("--animation-duration", "0")
        document.documentElement.style.setProperty("--animation-duration-long", "0")
        reloadCssVariables()
    }

    private _hideFilters(e?: MouseEvent) {
        e?.stopPropagation()
        this.showFilters = false
        m.redraw()
    }

    private _showFilters() {
        this.showFilters = true
        m.redraw()
    }

    view(vnode: this["Vnode"]) {
        const {
            colspan = 12,
            filters,
            grid,
            onclick,
            featureNotice,
            overlay,
            type,
        } = vnode.attrs

        const buildGridStyling = (grid: Attrs["grid"]) => {
            if (grid) {
                return Object.entries(grid).reduce((acc, [key, value]) => {
                    return acc += `grid-${key}: ${value};`
                }, "")
            }
        }

        const filterClass = this.showFilters ? "filter" : ""

        return (
            <div className={`${styles.card_wrapper} col-span-${colspan} type-${type}`}>
                <div
                    className={`card ${filterClass}`}
                    style={buildGridStyling(grid)}
                    onclick={onclick}
                >
                    { !this.showFilters ? <InsightFeedback type={type} /> : <></> }

                    { vnode.attrs.filters ? (
                        <>
                            <div className={`card-filters-overlay ${this.showFilters ? "active" : ""}`}></div>
                            <div className={`card-filters ${this.showFilters ? "active" : ""}`}>
                                <div className="card-filters-heading">
                                    <h3>{ t("filters", ns) }</h3>
                                    <i className="material-icons close-filters" onclick={(e: MouseEvent) => this._hideFilters(e)}>
                                        close
                                    </i>
                                </div>
                                { filters }
                            </div>
                        </>
                    ) : <></> }

                    { featureNotice ? (
                        <FeatureNotice
                            faded={featureNotice === "coming_soon"}
                            notice={featureNotice}
                        />
                    ) : <></> }

                    <Header
                        {...vnode.attrs.header}
                        hasFilters={this.hasFilters}
                        // Casting this to VnodeDOM since an interactive callback means the DOM is available.
                        downloadCardAsImage={() => this.downloadCardAsImage(vnode as unknown as this["VnodeDOM"])}
                        toggleFilters={this.toggleFilters.bind(this)}
                    />

                    <Overlay text={overlay?.text} cta={overlay?.cta} />

                    <div class="card-grid">
                        { vnode.children }
                    </div>
                </div>
            </div>
        )
    }
}

interface ExpandableCardFooterAttrs {
    text: string
}

export class ExpandableCardFooter extends MithrilTsx<ExpandableCardFooterAttrs> {
    expanded = false
    sendAnalytics = false

    onupdate() {
        if (this.expanded && !this.sendAnalytics) {
            analytics.event("expanded_card_footer")
            this.sendAnalytics = true
        }
    }

    view(vnode: this["Vnode"]) {
        const { text = t("click_to_expand", ns) } = vnode.attrs
        return (
            <div class="card-grid col-center-12">

                { this.expanded && vnode.children }

                <span
                    class={`expand col-center-12 ${this.expanded ? "expanded" : ""}`}
                    onclick={() => this.expanded = !this.expanded}
                >
                    { this.expanded ? t("click_to_fold", ns) : text }
                </span>
            </div>
        )
    }
}
