@use '../../css/variables' as *
@use '../../css/graphs/base'

.card_wrapper :global
    .card
        overflow: visible
        position: relative
        background-color: #ffffff
        padding: 1rem 2rem

        &:hover
            .insight-feedback-wrapper
                display: block

        &.filter
            overflow: hidden

        .card-info
            cursor: pointer

        .card-filter-icon
            cursor: pointer

        .card-options-icon
            display: flex
            align-items: center
            justify-content: center
            position: relative
            cursor: pointer
            border-radius: 0.5rem
            aspect-ratio: 1/1
            width: 2rem
            height: 2rem
            transition: background-color .1s ease-in-out

            &:hover, &.active
                background-color: var(--satys-light-accent)

            svg
                width: 1.5rem

        .card-options-menu
            position: absolute
            right: 0
            top: 100%
            width: max-content
            min-width: 12rem
            background-color: white
            border-radius: 0.5rem
            z-index: 11
            opacity: 0
            pointer-events: none
            transform: scale(.1)
            transform-origin: top right
            transition: transform, opacity .1s ease-out
            overflow: hidden

            &.show
                opacity: 1
                pointer-events: all
                transform: scale(1)
                filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.10))
                will-change: filter // To get safari to show the drop-shadow

            &-item
                display: flex
                flex-direction: row
                align-items: center
                padding: 0.5rem 1rem

                &:hover
                    background-color: var(--satys-light-accent)

                svg
                    width: 1.5rem
                    margin-right: 0.5rem

        .card-filters-overlay
            position: absolute
            z-index: 1
            inset: 0
            background: rgba(0, 0, 0, 0.1)
            border-radius: .5rem
            overflow: hidden
            pointer-events: none
            opacity: 0
            transition: opacity .2s ease-in-out

            &.active
                opacity: 1
                pointer-events: all

        .card-filters
            position: absolute
            right: 0
            top: 0
            bottom: 0
            width: max-content
            max-width: 40%
            overflow-y: auto
            background-color: white
            z-index: 2
            border-radius: 0 .5rem .5rem 0
            box-shadow: -10px 0px 15px -10px rgba(0, 0, 0, 0.2)
            padding: 1rem
            opacity: 0
            transform: translateX(100%)
            transition: translate .2s ease-in-out
            pointer-events: none

            &.active
                opacity: 1
                transform: translateX(0)
                transition: none
                pointer-events: all

            h4
                margin-bottom: 0

            ul
                list-style: none
                padding: 0

                li
                    display: inline-block

                    label
                        margin: 0
                        padding: 0 0.5rem

                        &.inline
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: center

                        input[type="checkbox"]:not(:checked) ~ i
                            color: var(--dark-grey)

            &-heading
                display: flex
                justify-content: space-between
                align-items: center
                padding: .5rem 0

                h3
                    margin: 0

                i
                    cursor: pointer

            .no-user-select
                -webkit-user-select: none

        @each $value, $color in base.$rating-five
            .sentiment-emoji-#{$value}
                color: $color
                fill: $color

    @media print
        .card
            margin-bottom: 1rem

.card_info_dialog :global
    .pe-dialog__content
        width: 64rem
        max-width: 80%

    .pe-dialog-pane
        max-width: 100%
