/* eslint-disable @typescript-eslint/no-explicit-any */
import { Message } from "google-protobuf"
import { ClientMethods } from "/src/services/IGrpcRequestQueue"

export type CacheKey = {
    method: ClientMethods,
    request: Message,
    metadata: Record<string, string>,
}

function generateKey(key: CacheKey): string {
    return `${key.method}:${JSON.stringify(key.request)}:${JSON.stringify(key.metadata)}`
}

export class GrpcCache {
    private cache: Map<string, any> = new Map()

    public get(key: CacheKey): any {
        return this.cache.get(generateKey(key))
    }

    public has(key: CacheKey): boolean {
        return this.cache.has(generateKey(key))
    }

    public set(key: CacheKey, value: any): void {
        this.cache.set(generateKey(key), value)
    }

    public clear(): void {
        this.cache.clear()
    }
}
