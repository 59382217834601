/**
 * Do a conversion from given index to an alphabetical character representation.
 *
 * @param index The index to convert, starts at 0=A.
 * When overflown, we start with AA, like Excel indexs.
 */
export function indexToChar(index: number): string {
    let temp: number = 0
    let char: string = ""
    // Make sure it works zero-indexed.
    index = index + 1
    while (index > 0) {
        temp = (index - 1) % 26
        char = String.fromCharCode(temp + 65) + char
        index = (index - temp - 1) / 26
    }
    return char
}
