// source: satys/datanalysis/datanalysis.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js');
goog.object.extend(proto, satys_domain_domain_pb);
var satys_generic_day_of_week_pb = require('../../satys/generic/day_of_week_pb.js');
goog.object.extend(proto, satys_generic_day_of_week_pb);
var satys_generic_time_of_day_pb = require('../../satys/generic/time_of_day_pb.js');
goog.object.extend(proto, satys_generic_time_of_day_pb);
goog.exportSymbol('proto.satys.datanalysis.AssignLabelRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.AssignLabelRequest.EntityCase', null, global);
goog.exportSymbol('proto.satys.datanalysis.AssignLabelResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateActionRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateActionResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateAnalysisRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateAnalysisResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateDashboardRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateDashboardResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateLabelRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateLabelResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateMeasurementRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateMeasurementResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateOrganisationRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateOrganisationResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateProcessFromBpmnRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateProcessFromBpmnResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateProductRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateProductResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateQuestionRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateQuestionResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateQuestionnaireRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateQuestionnaireResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.CreateRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.DeleteActionRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.DeleteActionResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetActiveChannelsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetActiveChannelsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnalysesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnalysesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersCountRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersCountResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersWithSentimentRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersWithSentimentResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetBpmnRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetBpmnResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetChannelRankingRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetChannelRankingResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetCohortStatsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetCohortStatsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetCohortStatsResponse.CohortStats', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetCurrentRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetCurrentRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetDashboardsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetDashboardsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetHourlyPatternRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetHourlyPatternResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetHourlyPatternResponse.GridItem', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetLabelRankingRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetLabelRankingResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetLabelsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetLabelsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMeasurementsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMeasurementsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMyDashboardsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMyDashboardsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetNotificationSettingsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetNotificationSettingsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationParentsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationParentsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationRolesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationRolesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationWithDescendantsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationWithDescendantsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetOrganisationsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPercentileRankRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPercentileRankResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPriorityStatsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPriorityStatsRequest.Strategy', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPriorityStatsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProbacTreeRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProbacTreeResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProcessesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProcessesRequest.OrgProdCase', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProcessesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProcessesResponse.OrgProdCase', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProductsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProductsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProfileRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetProfileResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQueryableOrganisationsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQueryableOrganisationsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnaireRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnaireResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnaireUrlsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnaireUrlsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnairesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionnairesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetQuestionsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsResponse.Typical', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsTimeseriesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsTimeseriesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetResponsesCountRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetResponsesCountResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetResponsesCountResponse.Typical', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetResponsesRangeRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetResponsesRangeResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRoleTasksRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRoleTasksResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRolesDashboardRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetRolesDashboardResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetSuborganisationRankingsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetSuborganisationRankingsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTasksRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTasksResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTasksWithActionsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTasksWithActionsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTawktoUserHashRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTawktoUserHashResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetThirdPartyOauthRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetThirdPartyOauthResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTypeformFormsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTypeformFormsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetTypeformFormsResponse.Form', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUserRolesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUserRolesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersByRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersByRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersWithRolesRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersWithRolesResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles', null, global);
goog.exportSymbol('proto.satys.datanalysis.LoginRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.LoginResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.LogoutRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.LogoutResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.RegisterUserRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.RegisterUserResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetActiveRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetActiveRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetMyPerformanceIndicatorRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetMyPerformanceIndicatorResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetPasswordRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetPasswordResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetPerformanceIndicatorsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetPerformanceIndicatorsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetProfileRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetProfileResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetRoleDashboardRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetRoleDashboardResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetThirdPartyOauthRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetThirdPartyOauthResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetUserRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.SetUserRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnassignLabelRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnassignLabelRequest.EntityCase', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnassignLabelResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnsetRoleDashboardRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnsetRoleDashboardResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnsetUserRoleRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UnsetUserRoleResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateDashboardRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateDashboardResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateNotificationSettingsRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateNotificationSettingsResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateOrganisationRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateOrganisationResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateQuestionRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateQuestionResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateQuestionnaireRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UpdateQuestionnaireResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse', null, global);
goog.exportSymbol('proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest', null, global);
goog.exportSymbol('proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.RegisterUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.RegisterUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.RegisterUserRequest.displayName = 'proto.satys.datanalysis.RegisterUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.RegisterUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.RegisterUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.RegisterUserResponse.displayName = 'proto.satys.datanalysis.RegisterUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetPasswordRequest.displayName = 'proto.satys.datanalysis.SetPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetPasswordResponse.displayName = 'proto.satys.datanalysis.SetPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.LoginRequest.displayName = 'proto.satys.datanalysis.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.LoginResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.LoginResponse.displayName = 'proto.satys.datanalysis.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.LogoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.LogoutRequest.displayName = 'proto.satys.datanalysis.LogoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.LogoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.LogoutResponse.displayName = 'proto.satys.datanalysis.LogoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUserRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetUserRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUserRolesRequest.displayName = 'proto.satys.datanalysis.GetUserRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUserRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetUserRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetUserRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUserRolesResponse.displayName = 'proto.satys.datanalysis.GetUserRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRoleTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRoleTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRoleTasksRequest.displayName = 'proto.satys.datanalysis.GetRoleTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRoleTasksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetRoleTasksResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetRoleTasksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRoleTasksResponse.displayName = 'proto.satys.datanalysis.GetRoleTasksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetUserRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetUserRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetUserRoleRequest.displayName = 'proto.satys.datanalysis.SetUserRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetUserRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetUserRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetUserRoleResponse.displayName = 'proto.satys.datanalysis.SetUserRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetActiveRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetActiveRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetActiveRoleRequest.displayName = 'proto.satys.datanalysis.SetActiveRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetActiveRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetActiveRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetActiveRoleResponse.displayName = 'proto.satys.datanalysis.SetActiveRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersRequest.displayName = 'proto.satys.datanalysis.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersResponse.displayName = 'proto.satys.datanalysis.GetUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersByRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersByRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersByRoleRequest.displayName = 'proto.satys.datanalysis.GetUsersByRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersByRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetUsersByRoleResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersByRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersByRoleResponse.displayName = 'proto.satys.datanalysis.GetUsersByRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTawktoUserHashRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTawktoUserHashRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTawktoUserHashRequest.displayName = 'proto.satys.datanalysis.GetTawktoUserHashRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTawktoUserHashResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTawktoUserHashResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTawktoUserHashResponse.displayName = 'proto.satys.datanalysis.GetTawktoUserHashResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersWithRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersWithRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersWithRolesRequest.displayName = 'proto.satys.datanalysis.GetUsersWithRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersWithRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetUsersWithRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersWithRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersWithRolesResponse.displayName = 'proto.satys.datanalysis.GetUsersWithRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.displayName = 'proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.displayName = 'proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.displayName = 'proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnsetUserRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UnsetUserRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnsetUserRoleRequest.displayName = 'proto.satys.datanalysis.UnsetUserRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnsetUserRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UnsetUserRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnsetUserRoleResponse.displayName = 'proto.satys.datanalysis.UnsetUserRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetProfileRequest.displayName = 'proto.satys.datanalysis.SetProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetProfileResponse.displayName = 'proto.satys.datanalysis.SetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProfileRequest.displayName = 'proto.satys.datanalysis.GetProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProfileResponse.displayName = 'proto.satys.datanalysis.GetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMyDashboardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetMyDashboardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMyDashboardsRequest.displayName = 'proto.satys.datanalysis.GetMyDashboardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMyDashboardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMyDashboardsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMyDashboardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMyDashboardsResponse.displayName = 'proto.satys.datanalysis.GetMyDashboardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetDashboardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetDashboardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetDashboardsRequest.displayName = 'proto.satys.datanalysis.GetDashboardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetDashboardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetDashboardsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetDashboardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetDashboardsResponse.displayName = 'proto.satys.datanalysis.GetDashboardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateDashboardRequest.displayName = 'proto.satys.datanalysis.CreateDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateDashboardResponse.displayName = 'proto.satys.datanalysis.CreateDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateDashboardRequest.displayName = 'proto.satys.datanalysis.UpdateDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateDashboardResponse.displayName = 'proto.satys.datanalysis.UpdateDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetNotificationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetNotificationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetNotificationSettingsRequest.displayName = 'proto.satys.datanalysis.GetNotificationSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetNotificationSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetNotificationSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetNotificationSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetNotificationSettingsResponse.displayName = 'proto.satys.datanalysis.GetNotificationSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.UpdateNotificationSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.UpdateNotificationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateNotificationSettingsRequest.displayName = 'proto.satys.datanalysis.UpdateNotificationSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateNotificationSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateNotificationSettingsResponse.displayName = 'proto.satys.datanalysis.UpdateNotificationSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateRoleRequest.displayName = 'proto.satys.datanalysis.CreateRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateRoleResponse.displayName = 'proto.satys.datanalysis.CreateRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetRoleDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetRoleDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetRoleDashboardRequest.displayName = 'proto.satys.datanalysis.SetRoleDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetRoleDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetRoleDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetRoleDashboardResponse.displayName = 'proto.satys.datanalysis.SetRoleDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UnsetRoleDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnsetRoleDashboardRequest.displayName = 'proto.satys.datanalysis.UnsetRoleDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UnsetRoleDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnsetRoleDashboardResponse.displayName = 'proto.satys.datanalysis.UnsetRoleDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRolesDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRolesDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRolesDashboardRequest.displayName = 'proto.satys.datanalysis.GetRolesDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRolesDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetRolesDashboardResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetRolesDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRolesDashboardResponse.displayName = 'proto.satys.datanalysis.GetRolesDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetCurrentRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetCurrentRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetCurrentRoleRequest.displayName = 'proto.satys.datanalysis.GetCurrentRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetCurrentRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetCurrentRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetCurrentRoleResponse.displayName = 'proto.satys.datanalysis.GetCurrentRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateActionRequest.displayName = 'proto.satys.datanalysis.CreateActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateActionResponse.displayName = 'proto.satys.datanalysis.CreateActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.DeleteActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.DeleteActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.DeleteActionRequest.displayName = 'proto.satys.datanalysis.DeleteActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.DeleteActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.DeleteActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.DeleteActionResponse.displayName = 'proto.satys.datanalysis.DeleteActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTasksRequest.displayName = 'proto.satys.datanalysis.GetTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTasksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetTasksResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetTasksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTasksResponse.displayName = 'proto.satys.datanalysis.GetTasksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTasksWithActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTasksWithActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTasksWithActionsRequest.displayName = 'proto.satys.datanalysis.GetTasksWithActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTasksWithActionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetTasksWithActionsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetTasksWithActionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTasksWithActionsResponse.displayName = 'proto.satys.datanalysis.GetTasksWithActionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProcessesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.satys.datanalysis.GetProcessesRequest.oneofGroups_);
};
goog.inherits(proto.satys.datanalysis.GetProcessesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProcessesRequest.displayName = 'proto.satys.datanalysis.GetProcessesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProcessesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetProcessesResponse.repeatedFields_, proto.satys.datanalysis.GetProcessesResponse.oneofGroups_);
};
goog.inherits(proto.satys.datanalysis.GetProcessesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProcessesResponse.displayName = 'proto.satys.datanalysis.GetProcessesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProductsRequest.displayName = 'proto.satys.datanalysis.GetProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProductsResponse.displayName = 'proto.satys.datanalysis.GetProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateProductRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateProductRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateProductRequest.displayName = 'proto.satys.datanalysis.CreateProductRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateProductResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateProductResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateProductResponse.displayName = 'proto.satys.datanalysis.CreateProductResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateOrganisationRequest.displayName = 'proto.satys.datanalysis.CreateOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateOrganisationResponse.displayName = 'proto.satys.datanalysis.CreateOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationRequest.displayName = 'proto.satys.datanalysis.GetOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationResponse.displayName = 'proto.satys.datanalysis.GetOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateOrganisationRequest.displayName = 'proto.satys.datanalysis.UpdateOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateOrganisationResponse.displayName = 'proto.satys.datanalysis.UpdateOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.SetPerformanceIndicatorsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.SetPerformanceIndicatorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetPerformanceIndicatorsRequest.displayName = 'proto.satys.datanalysis.SetPerformanceIndicatorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetPerformanceIndicatorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetPerformanceIndicatorsResponse.displayName = 'proto.satys.datanalysis.SetPerformanceIndicatorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationsRequest.displayName = 'proto.satys.datanalysis.GetOrganisationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetOrganisationsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationsResponse.displayName = 'proto.satys.datanalysis.GetOrganisationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationParentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationParentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationParentsRequest.displayName = 'proto.satys.datanalysis.GetOrganisationParentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationParentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationParentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationParentsResponse.displayName = 'proto.satys.datanalysis.GetOrganisationParentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationRolesRequest.displayName = 'proto.satys.datanalysis.GetOrganisationRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetOrganisationRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationRolesResponse.displayName = 'proto.satys.datanalysis.GetOrganisationRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQueryableOrganisationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQueryableOrganisationsRequest.displayName = 'proto.satys.datanalysis.GetQueryableOrganisationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetQueryableOrganisationsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetQueryableOrganisationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQueryableOrganisationsResponse.displayName = 'proto.satys.datanalysis.GetQueryableOrganisationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationWithDescendantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.displayName = 'proto.satys.datanalysis.GetOrganisationWithDescendantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetOrganisationWithDescendantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.displayName = 'proto.satys.datanalysis.GetOrganisationWithDescendantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProbacTreeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetProbacTreeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProbacTreeRequest.displayName = 'proto.satys.datanalysis.GetProbacTreeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetProbacTreeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetProbacTreeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetProbacTreeResponse.displayName = 'proto.satys.datanalysis.GetProbacTreeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMeasurementsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetMeasurementsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMeasurementsRequest.displayName = 'proto.satys.datanalysis.GetMeasurementsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMeasurementsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMeasurementsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMeasurementsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMeasurementsResponse.displayName = 'proto.satys.datanalysis.GetMeasurementsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateMeasurementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateMeasurementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateMeasurementRequest.displayName = 'proto.satys.datanalysis.CreateMeasurementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateMeasurementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateMeasurementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateMeasurementResponse.displayName = 'proto.satys.datanalysis.CreateMeasurementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnalysesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnalysesRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnalysesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnalysesRequest.displayName = 'proto.satys.datanalysis.GetAnalysesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnalysesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnalysesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnalysesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnalysesResponse.displayName = 'proto.satys.datanalysis.GetAnalysesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateAnalysisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateAnalysisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateAnalysisRequest.displayName = 'proto.satys.datanalysis.CreateAnalysisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateAnalysisResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateAnalysisResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateAnalysisResponse.displayName = 'proto.satys.datanalysis.CreateAnalysisResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnairesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetQuestionnairesRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnairesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnairesRequest.displayName = 'proto.satys.datanalysis.GetQuestionnairesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnairesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetQuestionnairesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnairesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnairesResponse.displayName = 'proto.satys.datanalysis.GetQuestionnairesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnaireRequest.displayName = 'proto.satys.datanalysis.GetQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnaireResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnaireResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnaireResponse.displayName = 'proto.satys.datanalysis.GetQuestionnaireResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnaireUrlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnaireUrlsRequest.displayName = 'proto.satys.datanalysis.GetQuestionnaireUrlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionnaireUrlsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionnaireUrlsResponse.displayName = 'proto.satys.datanalysis.GetQuestionnaireUrlsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateQuestionnaireRequest.displayName = 'proto.satys.datanalysis.CreateQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateQuestionnaireResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateQuestionnaireResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateQuestionnaireResponse.displayName = 'proto.satys.datanalysis.CreateQuestionnaireResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateQuestionnaireRequest.displayName = 'proto.satys.datanalysis.UpdateQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateQuestionnaireResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateQuestionnaireResponse.displayName = 'proto.satys.datanalysis.UpdateQuestionnaireResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetQuestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionsRequest.displayName = 'proto.satys.datanalysis.GetQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionsResponse.displayName = 'proto.satys.datanalysis.GetQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionRequest.displayName = 'proto.satys.datanalysis.GetQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetQuestionResponse.displayName = 'proto.satys.datanalysis.GetQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateQuestionRequest.displayName = 'proto.satys.datanalysis.CreateQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateQuestionResponse.displayName = 'proto.satys.datanalysis.CreateQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateQuestionRequest.displayName = 'proto.satys.datanalysis.UpdateQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UpdateQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UpdateQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UpdateQuestionResponse.displayName = 'proto.satys.datanalysis.UpdateQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.displayName = 'proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.displayName = 'proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.displayName = 'proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.displayName = 'proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.displayName = 'proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.displayName = 'proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateProcessFromBpmnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateProcessFromBpmnRequest.displayName = 'proto.satys.datanalysis.CreateProcessFromBpmnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateProcessFromBpmnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateProcessFromBpmnResponse.displayName = 'proto.satys.datanalysis.CreateProcessFromBpmnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetBpmnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetBpmnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetBpmnRequest.displayName = 'proto.satys.datanalysis.GetBpmnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetBpmnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetBpmnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetBpmnResponse.displayName = 'proto.satys.datanalysis.GetBpmnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetResponsesRangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetResponsesRangeRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetResponsesRangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetResponsesRangeRequest.displayName = 'proto.satys.datanalysis.GetResponsesRangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetResponsesRangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetResponsesRangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetResponsesRangeResponse.displayName = 'proto.satys.datanalysis.GetResponsesRangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetResponsesCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetResponsesCountRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetResponsesCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetResponsesCountRequest.displayName = 'proto.satys.datanalysis.GetResponsesCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetResponsesCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetResponsesCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetResponsesCountResponse.displayName = 'proto.satys.datanalysis.GetResponsesCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetResponsesCountResponse.Typical, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetResponsesCountResponse.Typical.displayName = 'proto.satys.datanalysis.GetResponsesCountResponse.Typical';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersCountRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersCountRequest.displayName = 'proto.satys.datanalysis.GetAnswersCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersCountResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersCountResponse.displayName = 'proto.satys.datanalysis.GetAnswersCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.displayName = 'proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersRequest.displayName = 'proto.satys.datanalysis.GetAnswersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersResponse.displayName = 'proto.satys.datanalysis.GetAnswersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersWithSentimentRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersWithSentimentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersWithSentimentRequest.displayName = 'proto.satys.datanalysis.GetAnswersWithSentimentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetAnswersWithSentimentResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersWithSentimentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersWithSentimentResponse.displayName = 'proto.satys.datanalysis.GetAnswersWithSentimentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.displayName = 'proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.displayName = 'proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.displayName = 'proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.displayName = 'proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.displayName = 'proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetSuborganisationRankingsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetSuborganisationRankingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetSuborganisationRankingsRequest.displayName = 'proto.satys.datanalysis.GetSuborganisationRankingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetSuborganisationRankingsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetSuborganisationRankingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetSuborganisationRankingsResponse.displayName = 'proto.satys.datanalysis.GetSuborganisationRankingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.displayName = 'proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetLabelRankingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetLabelRankingRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetLabelRankingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetLabelRankingRequest.displayName = 'proto.satys.datanalysis.GetLabelRankingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetLabelRankingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetLabelRankingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetLabelRankingResponse.displayName = 'proto.satys.datanalysis.GetLabelRankingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.displayName = 'proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetChannelRankingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetChannelRankingRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetChannelRankingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetChannelRankingRequest.displayName = 'proto.satys.datanalysis.GetChannelRankingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetChannelRankingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetChannelRankingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetChannelRankingResponse.displayName = 'proto.satys.datanalysis.GetChannelRankingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.displayName = 'proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetRatingStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsRequest.displayName = 'proto.satys.datanalysis.GetRatingStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsResponse.displayName = 'proto.satys.datanalysis.GetRatingStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsResponse.Typical, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsResponse.Typical.displayName = 'proto.satys.datanalysis.GetRatingStatsResponse.Typical';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsTimeseriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.displayName = 'proto.satys.datanalysis.GetRatingStatsTimeseriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsTimeseriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.displayName = 'proto.satys.datanalysis.GetRatingStatsTimeseriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.displayName = 'proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.displayName = 'proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.displayName = 'proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.displayName = 'proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetPriorityStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetPriorityStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetPriorityStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetPriorityStatsRequest.displayName = 'proto.satys.datanalysis.GetPriorityStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetPriorityStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetPriorityStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetPriorityStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetPriorityStatsResponse.displayName = 'proto.satys.datanalysis.GetPriorityStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.displayName = 'proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetCohortStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetCohortStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetCohortStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetCohortStatsRequest.displayName = 'proto.satys.datanalysis.GetCohortStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetCohortStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetCohortStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetCohortStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetCohortStatsResponse.displayName = 'proto.satys.datanalysis.GetCohortStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetCohortStatsResponse.CohortStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.displayName = 'proto.satys.datanalysis.GetCohortStatsResponse.CohortStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetPercentileRankRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetPercentileRankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetPercentileRankRequest.displayName = 'proto.satys.datanalysis.GetPercentileRankRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetPercentileRankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetPercentileRankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetPercentileRankResponse.displayName = 'proto.satys.datanalysis.GetPercentileRankResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetHourlyPatternRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetHourlyPatternRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetHourlyPatternRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetHourlyPatternRequest.displayName = 'proto.satys.datanalysis.GetHourlyPatternRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.displayName = 'proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetHourlyPatternResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetHourlyPatternResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetHourlyPatternResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetHourlyPatternResponse.displayName = 'proto.satys.datanalysis.GetHourlyPatternResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetHourlyPatternResponse.GridItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.displayName = 'proto.satys.datanalysis.GetHourlyPatternResponse.GridItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetThirdPartyOauthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetThirdPartyOauthRequest.displayName = 'proto.satys.datanalysis.SetThirdPartyOauthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetThirdPartyOauthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetThirdPartyOauthResponse.displayName = 'proto.satys.datanalysis.SetThirdPartyOauthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetThirdPartyOauthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetThirdPartyOauthRequest.displayName = 'proto.satys.datanalysis.GetThirdPartyOauthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetThirdPartyOauthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetThirdPartyOauthResponse.displayName = 'proto.satys.datanalysis.GetThirdPartyOauthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTypeformFormsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTypeformFormsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTypeformFormsRequest.displayName = 'proto.satys.datanalysis.GetTypeformFormsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTypeformFormsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetTypeformFormsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetTypeformFormsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTypeformFormsResponse.displayName = 'proto.satys.datanalysis.GetTypeformFormsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetTypeformFormsResponse.Form, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetTypeformFormsResponse.Form.displayName = 'proto.satys.datanalysis.GetTypeformFormsResponse.Form';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateLabelRequest.displayName = 'proto.satys.datanalysis.CreateLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.CreateLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.CreateLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.CreateLabelResponse.displayName = 'proto.satys.datanalysis.CreateLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.AssignLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_);
};
goog.inherits(proto.satys.datanalysis.AssignLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.AssignLabelRequest.displayName = 'proto.satys.datanalysis.AssignLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.AssignLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.AssignLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.AssignLabelResponse.displayName = 'proto.satys.datanalysis.AssignLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnassignLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_);
};
goog.inherits(proto.satys.datanalysis.UnassignLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnassignLabelRequest.displayName = 'proto.satys.datanalysis.UnassignLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.UnassignLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.UnassignLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.UnassignLabelResponse.displayName = 'proto.satys.datanalysis.UnassignLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetLabelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetLabelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetLabelsRequest.displayName = 'proto.satys.datanalysis.GetLabelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetLabelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetLabelsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetLabelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetLabelsResponse.displayName = 'proto.satys.datanalysis.GetLabelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetMyPerformanceIndicatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.displayName = 'proto.satys.datanalysis.SetMyPerformanceIndicatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.SetMyPerformanceIndicatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.displayName = 'proto.satys.datanalysis.SetMyPerformanceIndicatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.displayName = 'proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.displayName = 'proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetActiveChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetActiveChannelsRequest.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetActiveChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetActiveChannelsRequest.displayName = 'proto.satys.datanalysis.GetActiveChannelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.datanalysis.GetActiveChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.datanalysis.GetActiveChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.datanalysis.GetActiveChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.datanalysis.GetActiveChannelsResponse.displayName = 'proto.satys.datanalysis.GetActiveChannelsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.RegisterUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.RegisterUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.RegisterUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && satys_domain_domain_pb.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.RegisterUserRequest}
 */
proto.satys.datanalysis.RegisterUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.RegisterUserRequest;
  return proto.satys.datanalysis.RegisterUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.RegisterUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.RegisterUserRequest}
 */
proto.satys.datanalysis.RegisterUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Profile;
      reader.readMessage(value,satys_domain_domain_pb.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.RegisterUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.RegisterUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.RegisterUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.RegisterUserRequest} returns this
*/
proto.satys.datanalysis.RegisterUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.RegisterUserRequest} returns this
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Profile profile = 2;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Profile, 2));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.datanalysis.RegisterUserRequest} returns this
*/
proto.satys.datanalysis.RegisterUserRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.RegisterUserRequest} returns this
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.RegisterUserRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.RegisterUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.RegisterUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.RegisterUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.RegisterUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.RegisterUserResponse}
 */
proto.satys.datanalysis.RegisterUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.RegisterUserResponse;
  return proto.satys.datanalysis.RegisterUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.RegisterUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.RegisterUserResponse}
 */
proto.satys.datanalysis.RegisterUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.RegisterUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.RegisterUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.RegisterUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.RegisterUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.satys.datanalysis.RegisterUserResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.RegisterUserResponse} returns this
 */
proto.satys.datanalysis.RegisterUserResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invalidateTokens: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetPasswordRequest}
 */
proto.satys.datanalysis.SetPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetPasswordRequest;
  return proto.satys.datanalysis.SetPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetPasswordRequest}
 */
proto.satys.datanalysis.SetPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvalidateTokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvalidateTokens();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.SetPasswordRequest} returns this
*/
proto.satys.datanalysis.SetPasswordRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetPasswordRequest} returns this
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.SetPasswordRequest} returns this
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool invalidate_tokens = 3;
 * @return {boolean}
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.getInvalidateTokens = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.SetPasswordRequest} returns this
 */
proto.satys.datanalysis.SetPasswordRequest.prototype.setInvalidateTokens = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetPasswordResponse}
 */
proto.satys.datanalysis.SetPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetPasswordResponse;
  return proto.satys.datanalysis.SetPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetPasswordResponse}
 */
proto.satys.datanalysis.SetPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.LoginRequest}
 */
proto.satys.datanalysis.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.LoginRequest;
  return proto.satys.datanalysis.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.LoginRequest}
 */
proto.satys.datanalysis.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.LoginRequest.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.LoginRequest} returns this
*/
proto.satys.datanalysis.LoginRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.LoginRequest} returns this
 */
proto.satys.datanalysis.LoginRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.LoginRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.LoginResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    expirationTime: (f = msg.getExpirationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.LoginResponse}
 */
proto.satys.datanalysis.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.LoginResponse;
  return proto.satys.datanalysis.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.LoginResponse}
 */
proto.satys.datanalysis.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.satys.datanalysis.LoginResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
 */
proto.satys.datanalysis.LoginResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional satys.domain.User user = 2;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.LoginResponse.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 2));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
*/
proto.satys.datanalysis.LoginResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
 */
proto.satys.datanalysis.LoginResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.LoginResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.LoginResponse.prototype.getExpirationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
*/
proto.satys.datanalysis.LoginResponse.prototype.setExpirationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
 */
proto.satys.datanalysis.LoginResponse.prototype.clearExpirationTime = function() {
  return this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.LoginResponse.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated satys.domain.Role roles = 4;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.LoginResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
*/
proto.satys.datanalysis.LoginResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.LoginResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.LoginResponse} returns this
 */
proto.satys.datanalysis.LoginResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.LogoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.LogoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.LogoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LogoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.LogoutRequest}
 */
proto.satys.datanalysis.LogoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.LogoutRequest;
  return proto.satys.datanalysis.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.LogoutRequest}
 */
proto.satys.datanalysis.LogoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.LogoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LogoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.LogoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.LogoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.LogoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LogoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.LogoutResponse}
 */
proto.satys.datanalysis.LogoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.LogoutResponse;
  return proto.satys.datanalysis.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.LogoutResponse}
 */
proto.satys.datanalysis.LogoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.LogoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.LogoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.LogoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.LogoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.LogoutResponse} returns this
 */
proto.satys.datanalysis.LogoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUserRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUserRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUserRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUserRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depth: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUserRolesRequest}
 */
proto.satys.datanalysis.GetUserRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUserRolesRequest;
  return proto.satys.datanalysis.GetUserRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUserRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUserRolesRequest}
 */
proto.satys.datanalysis.GetUserRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUserRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUserRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUserRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUserRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 depth = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetUserRolesRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetUserRolesRequest} returns this
 */
proto.satys.datanalysis.GetUserRolesRequest.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetUserRolesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUserRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUserRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUserRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUserRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUserRolesResponse}
 */
proto.satys.datanalysis.GetUserRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUserRolesResponse;
  return proto.satys.datanalysis.GetUserRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUserRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUserRolesResponse}
 */
proto.satys.datanalysis.GetUserRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUserRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUserRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUserRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUserRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Role roles = 2;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.GetUserRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.GetUserRolesResponse} returns this
*/
proto.satys.datanalysis.GetUserRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetUserRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetUserRolesResponse} returns this
 */
proto.satys.datanalysis.GetUserRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRoleTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRoleTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRoleTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRoleTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRoleTasksRequest}
 */
proto.satys.datanalysis.GetRoleTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRoleTasksRequest;
  return proto.satys.datanalysis.GetRoleTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRoleTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRoleTasksRequest}
 */
proto.satys.datanalysis.GetRoleTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRoleTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRoleTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRoleTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRoleTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetRoleTasksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRoleTasksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRoleTasksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRoleTasksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRoleTasksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    satys_domain_domain_pb.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRoleTasksResponse}
 */
proto.satys.datanalysis.GetRoleTasksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRoleTasksResponse;
  return proto.satys.datanalysis.GetRoleTasksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRoleTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRoleTasksResponse}
 */
proto.satys.datanalysis.GetRoleTasksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Task;
      reader.readMessage(value,satys_domain_domain_pb.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRoleTasksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRoleTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRoleTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRoleTasksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Task.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Task tasks = 1;
 * @return {!Array<!proto.satys.domain.Task>}
 */
proto.satys.datanalysis.GetRoleTasksResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.satys.domain.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Task, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Task>} value
 * @return {!proto.satys.datanalysis.GetRoleTasksResponse} returns this
*/
proto.satys.datanalysis.GetRoleTasksResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Task}
 */
proto.satys.datanalysis.GetRoleTasksResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRoleTasksResponse} returns this
 */
proto.satys.datanalysis.GetRoleTasksResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetUserRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetUserRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetUserRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetUserRoleRequest}
 */
proto.satys.datanalysis.SetUserRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetUserRoleRequest;
  return proto.satys.datanalysis.SetUserRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetUserRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetUserRoleRequest}
 */
proto.satys.datanalysis.SetUserRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetUserRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetUserRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetUserRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.SetUserRoleRequest} returns this
*/
proto.satys.datanalysis.SetUserRoleRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetUserRoleRequest} returns this
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Role role = 2;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 2));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.SetUserRoleRequest} returns this
*/
proto.satys.datanalysis.SetUserRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetUserRoleRequest} returns this
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetUserRoleRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetUserRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetUserRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetUserRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetUserRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetUserRoleResponse}
 */
proto.satys.datanalysis.SetUserRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetUserRoleResponse;
  return proto.satys.datanalysis.SetUserRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetUserRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetUserRoleResponse}
 */
proto.satys.datanalysis.SetUserRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetUserRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetUserRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetUserRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetUserRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.SetUserRoleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.SetUserRoleResponse} returns this
 */
proto.satys.datanalysis.SetUserRoleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetActiveRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetActiveRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetActiveRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetActiveRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetActiveRoleRequest}
 */
proto.satys.datanalysis.SetActiveRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetActiveRoleRequest;
  return proto.satys.datanalysis.SetActiveRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetActiveRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetActiveRoleRequest}
 */
proto.satys.datanalysis.SetActiveRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetActiveRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetActiveRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetActiveRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetActiveRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 2;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.SetActiveRoleRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 2));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.SetActiveRoleRequest} returns this
*/
proto.satys.datanalysis.SetActiveRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetActiveRoleRequest} returns this
 */
proto.satys.datanalysis.SetActiveRoleRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetActiveRoleRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetActiveRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetActiveRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetActiveRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetActiveRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetActiveRoleResponse}
 */
proto.satys.datanalysis.SetActiveRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetActiveRoleResponse;
  return proto.satys.datanalysis.SetActiveRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetActiveRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetActiveRoleResponse}
 */
proto.satys.datanalysis.SetActiveRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetActiveRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetActiveRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetActiveRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetActiveRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.satys.datanalysis.SetActiveRoleResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.SetActiveRoleResponse} returns this
 */
proto.satys.datanalysis.SetActiveRoleResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersRequest}
 */
proto.satys.datanalysis.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersRequest;
  return proto.satys.datanalysis.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersRequest}
 */
proto.satys.datanalysis.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    satys_domain_domain_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersResponse}
 */
proto.satys.datanalysis.GetUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersResponse;
  return proto.satys.datanalysis.GetUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersResponse}
 */
proto.satys.datanalysis.GetUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.User users = 1;
 * @return {!Array<!proto.satys.domain.User>}
 */
proto.satys.datanalysis.GetUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.satys.domain.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {!Array<!proto.satys.domain.User>} value
 * @return {!proto.satys.datanalysis.GetUsersResponse} returns this
*/
proto.satys.datanalysis.GetUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.User}
 */
proto.satys.datanalysis.GetUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetUsersResponse} returns this
 */
proto.satys.datanalysis.GetUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersByRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersByRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersByRoleRequest}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersByRoleRequest;
  return proto.satys.datanalysis.GetUsersByRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersByRoleRequest}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersByRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersByRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.GetUsersByRoleRequest} returns this
*/
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetUsersByRoleRequest} returns this
 */
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetUsersByRoleRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetUsersByRoleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersByRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersByRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersByRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    satys_domain_domain_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersByRoleResponse}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersByRoleResponse;
  return proto.satys.datanalysis.GetUsersByRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersByRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersByRoleResponse}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersByRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersByRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersByRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.User users = 1;
 * @return {!Array<!proto.satys.domain.User>}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.satys.domain.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {!Array<!proto.satys.domain.User>} value
 * @return {!proto.satys.datanalysis.GetUsersByRoleResponse} returns this
*/
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.User}
 */
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetUsersByRoleResponse} returns this
 */
proto.satys.datanalysis.GetUsersByRoleResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTawktoUserHashRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTawktoUserHashRequest}
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTawktoUserHashRequest;
  return proto.satys.datanalysis.GetTawktoUserHashRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTawktoUserHashRequest}
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTawktoUserHashRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTawktoUserHashRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTawktoUserHashResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTawktoUserHashResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTawktoUserHashResponse}
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTawktoUserHashResponse;
  return proto.satys.datanalysis.GetTawktoUserHashResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTawktoUserHashResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTawktoUserHashResponse}
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTawktoUserHashResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTawktoUserHashResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetTawktoUserHashResponse} returns this
 */
proto.satys.datanalysis.GetTawktoUserHashResponse.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersWithRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesRequest}
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersWithRolesRequest;
  return proto.satys.datanalysis.GetUsersWithRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesRequest}
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersWithRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersWithRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersWithRolesList: jspb.Message.toObjectList(msg.getUsersWithRolesList(),
    proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersWithRolesResponse;
  return proto.satys.datanalysis.GetUsersWithRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles;
      reader.readMessage(value,proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.deserializeBinaryFromReader);
      msg.addUsersWithRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersWithRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersWithRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles;
  return proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} returns this
*/
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} returns this
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.Role roles = 2;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} returns this
*/
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles} returns this
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated UserWithRoles users_with_roles = 1;
 * @return {!Array<!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles>}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.getUsersWithRolesList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles>} value
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse} returns this
*/
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.setUsersWithRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles}
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.addUsersWithRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetUsersWithRolesResponse.UserWithRoles, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetUsersWithRolesResponse} returns this
 */
proto.satys.datanalysis.GetUsersWithRolesResponse.prototype.clearUsersWithRolesList = function() {
  return this.setUsersWithRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest;
  return proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse;
  return proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Role roles = 1;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnsetUserRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetUserRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && satys_domain_domain_pb.User.toObject(includeInstance, f),
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnsetUserRoleRequest;
  return proto.satys.datanalysis.UnsetUserRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.User;
      reader.readMessage(value,satys_domain_domain_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnsetUserRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetUserRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest} returns this
*/
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest} returns this
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Role role = 2;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 2));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest} returns this
*/
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnsetUserRoleRequest} returns this
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnsetUserRoleRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnsetUserRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnsetUserRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnsetUserRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetUserRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnsetUserRoleResponse}
 */
proto.satys.datanalysis.UnsetUserRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnsetUserRoleResponse;
  return proto.satys.datanalysis.UnsetUserRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnsetUserRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnsetUserRoleResponse}
 */
proto.satys.datanalysis.UnsetUserRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnsetUserRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnsetUserRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnsetUserRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetUserRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && satys_domain_domain_pb.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetProfileRequest}
 */
proto.satys.datanalysis.SetProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetProfileRequest;
  return proto.satys.datanalysis.SetProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetProfileRequest}
 */
proto.satys.datanalysis.SetProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Profile;
      reader.readMessage(value,satys_domain_domain_pb.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Profile profile = 1;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.datanalysis.SetProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Profile, 1));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.datanalysis.SetProfileRequest} returns this
*/
proto.satys.datanalysis.SetProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetProfileRequest} returns this
 */
proto.satys.datanalysis.SetProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetProfileResponse}
 */
proto.satys.datanalysis.SetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetProfileResponse;
  return proto.satys.datanalysis.SetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetProfileResponse}
 */
proto.satys.datanalysis.SetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.SetProfileResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.SetProfileResponse} returns this
 */
proto.satys.datanalysis.SetProfileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProfileRequest}
 */
proto.satys.datanalysis.GetProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProfileRequest;
  return proto.satys.datanalysis.GetProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProfileRequest}
 */
proto.satys.datanalysis.GetProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && satys_domain_domain_pb.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProfileResponse}
 */
proto.satys.datanalysis.GetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProfileResponse;
  return proto.satys.datanalysis.GetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProfileResponse}
 */
proto.satys.datanalysis.GetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Profile;
      reader.readMessage(value,satys_domain_domain_pb.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Profile profile = 1;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.datanalysis.GetProfileResponse.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Profile, 1));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.datanalysis.GetProfileResponse} returns this
*/
proto.satys.datanalysis.GetProfileResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProfileResponse} returns this
 */
proto.satys.datanalysis.GetProfileResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProfileResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMyDashboardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMyDashboardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyDashboardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMyDashboardsRequest}
 */
proto.satys.datanalysis.GetMyDashboardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMyDashboardsRequest;
  return proto.satys.datanalysis.GetMyDashboardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMyDashboardsRequest}
 */
proto.satys.datanalysis.GetMyDashboardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMyDashboardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMyDashboardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyDashboardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMyDashboardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMyDashboardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMyDashboardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyDashboardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardsList: jspb.Message.toObjectList(msg.getDashboardsList(),
    satys_domain_domain_pb.Dashboard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMyDashboardsResponse}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMyDashboardsResponse;
  return proto.satys.datanalysis.GetMyDashboardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMyDashboardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMyDashboardsResponse}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.addDashboards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMyDashboardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMyDashboardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyDashboardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Dashboard dashboards = 1;
 * @return {!Array<!proto.satys.domain.Dashboard>}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.getDashboardsList = function() {
  return /** @type{!Array<!proto.satys.domain.Dashboard>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Dashboard, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Dashboard>} value
 * @return {!proto.satys.datanalysis.GetMyDashboardsResponse} returns this
*/
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.setDashboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Dashboard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.addDashboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Dashboard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMyDashboardsResponse} returns this
 */
proto.satys.datanalysis.GetMyDashboardsResponse.prototype.clearDashboardsList = function() {
  return this.setDashboardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetDashboardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetDashboardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetDashboardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetDashboardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetDashboardsRequest}
 */
proto.satys.datanalysis.GetDashboardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetDashboardsRequest;
  return proto.satys.datanalysis.GetDashboardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetDashboardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetDashboardsRequest}
 */
proto.satys.datanalysis.GetDashboardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetDashboardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetDashboardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetDashboardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetDashboardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetDashboardsRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.GetDashboardsRequest} returns this
*/
proto.satys.datanalysis.GetDashboardsRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetDashboardsRequest} returns this
 */
proto.satys.datanalysis.GetDashboardsRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetDashboardsRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetDashboardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetDashboardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetDashboardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetDashboardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetDashboardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardsList: jspb.Message.toObjectList(msg.getDashboardsList(),
    satys_domain_domain_pb.Dashboard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetDashboardsResponse}
 */
proto.satys.datanalysis.GetDashboardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetDashboardsResponse;
  return proto.satys.datanalysis.GetDashboardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetDashboardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetDashboardsResponse}
 */
proto.satys.datanalysis.GetDashboardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.addDashboards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetDashboardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetDashboardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetDashboardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetDashboardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Dashboard dashboards = 1;
 * @return {!Array<!proto.satys.domain.Dashboard>}
 */
proto.satys.datanalysis.GetDashboardsResponse.prototype.getDashboardsList = function() {
  return /** @type{!Array<!proto.satys.domain.Dashboard>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Dashboard, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Dashboard>} value
 * @return {!proto.satys.datanalysis.GetDashboardsResponse} returns this
*/
proto.satys.datanalysis.GetDashboardsResponse.prototype.setDashboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Dashboard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.GetDashboardsResponse.prototype.addDashboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Dashboard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetDashboardsResponse} returns this
 */
proto.satys.datanalysis.GetDashboardsResponse.prototype.clearDashboardsList = function() {
  return this.setDashboardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboard: (f = msg.getDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateDashboardRequest}
 */
proto.satys.datanalysis.CreateDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateDashboardRequest;
  return proto.satys.datanalysis.CreateDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateDashboardRequest}
 */
proto.satys.datanalysis.CreateDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setDashboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Dashboard dashboard = 1;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.CreateDashboardRequest.prototype.getDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 1));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.CreateDashboardRequest} returns this
*/
proto.satys.datanalysis.CreateDashboardRequest.prototype.setDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateDashboardRequest} returns this
 */
proto.satys.datanalysis.CreateDashboardRequest.prototype.clearDashboard = function() {
  return this.setDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateDashboardRequest.prototype.hasDashboard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateDashboardResponse}
 */
proto.satys.datanalysis.CreateDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateDashboardResponse;
  return proto.satys.datanalysis.CreateDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateDashboardResponse}
 */
proto.satys.datanalysis.CreateDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldDashboard: (f = msg.getOldDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f),
    newDashboard: (f = msg.getNewDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest}
 */
proto.satys.datanalysis.UpdateDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateDashboardRequest;
  return proto.satys.datanalysis.UpdateDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest}
 */
proto.satys.datanalysis.UpdateDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setOldDashboard(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setNewDashboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldDashboard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
  f = message.getNewDashboard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Dashboard old_dashboard = 1;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.getOldDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 1));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest} returns this
*/
proto.satys.datanalysis.UpdateDashboardRequest.prototype.setOldDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest} returns this
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.clearOldDashboard = function() {
  return this.setOldDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.hasOldDashboard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Dashboard new_dashboard = 2;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.getNewDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 2));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest} returns this
*/
proto.satys.datanalysis.UpdateDashboardRequest.prototype.setNewDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateDashboardRequest} returns this
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.clearNewDashboard = function() {
  return this.setNewDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateDashboardRequest.prototype.hasNewDashboard = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateDashboardResponse}
 */
proto.satys.datanalysis.UpdateDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateDashboardResponse;
  return proto.satys.datanalysis.UpdateDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateDashboardResponse}
 */
proto.satys.datanalysis.UpdateDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetNotificationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && satys_domain_domain_pb.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsRequest}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetNotificationSettingsRequest;
  return proto.satys.datanalysis.GetNotificationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsRequest}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Profile;
      reader.readMessage(value,satys_domain_domain_pb.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetNotificationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Profile profile = 1;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Profile, 1));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.datanalysis.GetNotificationSettingsRequest} returns this
*/
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsRequest} returns this
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetNotificationSettingsRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetNotificationSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetNotificationSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationSettingsList: jspb.Message.toObjectList(msg.getNotificationSettingsList(),
    satys_domain_domain_pb.NotificationSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsResponse}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetNotificationSettingsResponse;
  return proto.satys.datanalysis.GetNotificationSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetNotificationSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsResponse}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.NotificationSetting;
      reader.readMessage(value,satys_domain_domain_pb.NotificationSetting.deserializeBinaryFromReader);
      msg.addNotificationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetNotificationSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetNotificationSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.NotificationSetting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.NotificationSetting notification_settings = 1;
 * @return {!Array<!proto.satys.domain.NotificationSetting>}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.getNotificationSettingsList = function() {
  return /** @type{!Array<!proto.satys.domain.NotificationSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.NotificationSetting, 1));
};


/**
 * @param {!Array<!proto.satys.domain.NotificationSetting>} value
 * @return {!proto.satys.datanalysis.GetNotificationSettingsResponse} returns this
*/
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.setNotificationSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.NotificationSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.NotificationSetting}
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.addNotificationSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.NotificationSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetNotificationSettingsResponse} returns this
 */
proto.satys.datanalysis.GetNotificationSettingsResponse.prototype.clearNotificationSettingsList = function() {
  return this.setNotificationSettingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateNotificationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && satys_domain_domain_pb.Profile.toObject(includeInstance, f),
    notificationSettingsList: jspb.Message.toObjectList(msg.getNotificationSettingsList(),
    satys_domain_domain_pb.NotificationSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateNotificationSettingsRequest;
  return proto.satys.datanalysis.UpdateNotificationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Profile;
      reader.readMessage(value,satys_domain_domain_pb.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.NotificationSetting;
      reader.readMessage(value,satys_domain_domain_pb.NotificationSetting.deserializeBinaryFromReader);
      msg.addNotificationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateNotificationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Profile.serializeBinaryToWriter
    );
  }
  f = message.getNotificationSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.NotificationSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Profile profile = 1;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Profile, 1));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} returns this
*/
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} returns this
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.NotificationSetting notification_settings = 2;
 * @return {!Array<!proto.satys.domain.NotificationSetting>}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.getNotificationSettingsList = function() {
  return /** @type{!Array<!proto.satys.domain.NotificationSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.NotificationSetting, 2));
};


/**
 * @param {!Array<!proto.satys.domain.NotificationSetting>} value
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} returns this
*/
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.setNotificationSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.NotificationSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.NotificationSetting}
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.addNotificationSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.NotificationSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} returns this
 */
proto.satys.datanalysis.UpdateNotificationSettingsRequest.prototype.clearNotificationSettingsList = function() {
  return this.setNotificationSettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateNotificationSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsResponse}
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateNotificationSettingsResponse;
  return proto.satys.datanalysis.UpdateNotificationSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateNotificationSettingsResponse}
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateNotificationSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateNotificationSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateRoleRequest}
 */
proto.satys.datanalysis.CreateRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateRoleRequest;
  return proto.satys.datanalysis.CreateRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateRoleRequest}
 */
proto.satys.datanalysis.CreateRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.CreateRoleRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.CreateRoleRequest} returns this
*/
proto.satys.datanalysis.CreateRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateRoleRequest} returns this
 */
proto.satys.datanalysis.CreateRoleRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateRoleRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateRoleResponse}
 */
proto.satys.datanalysis.CreateRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateRoleResponse;
  return proto.satys.datanalysis.CreateRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateRoleResponse}
 */
proto.satys.datanalysis.CreateRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.CreateRoleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.CreateRoleResponse} returns this
 */
proto.satys.datanalysis.CreateRoleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetRoleDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetRoleDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f),
    dashboard: (f = msg.getDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetRoleDashboardRequest;
  return proto.satys.datanalysis.SetRoleDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setDashboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetRoleDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetRoleDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
  f = message.getDashboard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest} returns this
*/
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest} returns this
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Dashboard dashboard = 2;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.getDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 2));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest} returns this
*/
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.setDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetRoleDashboardRequest} returns this
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.clearDashboard = function() {
  return this.setDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetRoleDashboardRequest.prototype.hasDashboard = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetRoleDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetRoleDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetRoleDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetRoleDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetRoleDashboardResponse}
 */
proto.satys.datanalysis.SetRoleDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetRoleDashboardResponse;
  return proto.satys.datanalysis.SetRoleDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetRoleDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetRoleDashboardResponse}
 */
proto.satys.datanalysis.SetRoleDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetRoleDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetRoleDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetRoleDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetRoleDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnsetRoleDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f),
    dashboard: (f = msg.getDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnsetRoleDashboardRequest;
  return proto.satys.datanalysis.UnsetRoleDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setDashboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnsetRoleDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
  f = message.getDashboard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest} returns this
*/
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest} returns this
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Dashboard dashboard = 2;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.getDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 2));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest} returns this
*/
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.setDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardRequest} returns this
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.clearDashboard = function() {
  return this.setDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnsetRoleDashboardRequest.prototype.hasDashboard = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnsetRoleDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardResponse}
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnsetRoleDashboardResponse;
  return proto.satys.datanalysis.UnsetRoleDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnsetRoleDashboardResponse}
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnsetRoleDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnsetRoleDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRolesDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRolesDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboard: (f = msg.getDashboard()) && satys_domain_domain_pb.Dashboard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRolesDashboardRequest}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRolesDashboardRequest;
  return proto.satys.datanalysis.GetRolesDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRolesDashboardRequest}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new satys_domain_domain_pb.Dashboard;
      reader.readMessage(value,satys_domain_domain_pb.Dashboard.deserializeBinaryFromReader);
      msg.setDashboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRolesDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRolesDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Dashboard dashboard = 2;
 * @return {?proto.satys.domain.Dashboard}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.getDashboard = function() {
  return /** @type{?proto.satys.domain.Dashboard} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Dashboard, 2));
};


/**
 * @param {?proto.satys.domain.Dashboard|undefined} value
 * @return {!proto.satys.datanalysis.GetRolesDashboardRequest} returns this
*/
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.setDashboard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRolesDashboardRequest} returns this
 */
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.clearDashboard = function() {
  return this.setDashboard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRolesDashboardRequest.prototype.hasDashboard = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetRolesDashboardResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRolesDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRolesDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRolesDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRolesDashboardResponse}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRolesDashboardResponse;
  return proto.satys.datanalysis.GetRolesDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRolesDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRolesDashboardResponse}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRolesDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRolesDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRolesDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Role roles = 1;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.GetRolesDashboardResponse} returns this
*/
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRolesDashboardResponse} returns this
 */
proto.satys.datanalysis.GetRolesDashboardResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetCurrentRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetCurrentRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCurrentRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetCurrentRoleRequest}
 */
proto.satys.datanalysis.GetCurrentRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetCurrentRoleRequest;
  return proto.satys.datanalysis.GetCurrentRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetCurrentRoleRequest}
 */
proto.satys.datanalysis.GetCurrentRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetCurrentRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetCurrentRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCurrentRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetCurrentRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetCurrentRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCurrentRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && satys_domain_domain_pb.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetCurrentRoleResponse}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetCurrentRoleResponse;
  return proto.satys.datanalysis.GetCurrentRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetCurrentRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetCurrentRoleResponse}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetCurrentRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetCurrentRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCurrentRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Role role = 1;
 * @return {?proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.getRole = function() {
  return /** @type{?proto.satys.domain.Role} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {?proto.satys.domain.Role|undefined} value
 * @return {!proto.satys.datanalysis.GetCurrentRoleResponse} returns this
*/
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetCurrentRoleResponse} returns this
 */
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetCurrentRoleResponse.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: (f = msg.getAction()) && satys_domain_domain_pb.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateActionRequest}
 */
proto.satys.datanalysis.CreateActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateActionRequest;
  return proto.satys.datanalysis.CreateActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateActionRequest}
 */
proto.satys.datanalysis.CreateActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Action;
      reader.readMessage(value,satys_domain_domain_pb.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Action action = 1;
 * @return {?proto.satys.domain.Action}
 */
proto.satys.datanalysis.CreateActionRequest.prototype.getAction = function() {
  return /** @type{?proto.satys.domain.Action} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Action, 1));
};


/**
 * @param {?proto.satys.domain.Action|undefined} value
 * @return {!proto.satys.datanalysis.CreateActionRequest} returns this
*/
proto.satys.datanalysis.CreateActionRequest.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateActionRequest} returns this
 */
proto.satys.datanalysis.CreateActionRequest.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateActionRequest.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateActionResponse}
 */
proto.satys.datanalysis.CreateActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateActionResponse;
  return proto.satys.datanalysis.CreateActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateActionResponse}
 */
proto.satys.datanalysis.CreateActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.CreateActionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.CreateActionResponse} returns this
 */
proto.satys.datanalysis.CreateActionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.DeleteActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.DeleteActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.DeleteActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.DeleteActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: (f = msg.getAction()) && satys_domain_domain_pb.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.DeleteActionRequest}
 */
proto.satys.datanalysis.DeleteActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.DeleteActionRequest;
  return proto.satys.datanalysis.DeleteActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.DeleteActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.DeleteActionRequest}
 */
proto.satys.datanalysis.DeleteActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Action;
      reader.readMessage(value,satys_domain_domain_pb.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.DeleteActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.DeleteActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.DeleteActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.DeleteActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Action action = 1;
 * @return {?proto.satys.domain.Action}
 */
proto.satys.datanalysis.DeleteActionRequest.prototype.getAction = function() {
  return /** @type{?proto.satys.domain.Action} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Action, 1));
};


/**
 * @param {?proto.satys.domain.Action|undefined} value
 * @return {!proto.satys.datanalysis.DeleteActionRequest} returns this
*/
proto.satys.datanalysis.DeleteActionRequest.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.DeleteActionRequest} returns this
 */
proto.satys.datanalysis.DeleteActionRequest.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.DeleteActionRequest.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.DeleteActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.DeleteActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.DeleteActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.DeleteActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.DeleteActionResponse}
 */
proto.satys.datanalysis.DeleteActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.DeleteActionResponse;
  return proto.satys.datanalysis.DeleteActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.DeleteActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.DeleteActionResponse}
 */
proto.satys.datanalysis.DeleteActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.DeleteActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.DeleteActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.DeleteActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.DeleteActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.DeleteActionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.DeleteActionResponse} returns this
 */
proto.satys.datanalysis.DeleteActionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTasksRequest}
 */
proto.satys.datanalysis.GetTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTasksRequest;
  return proto.satys.datanalysis.GetTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTasksRequest}
 */
proto.satys.datanalysis.GetTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetTasksRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.GetTasksRequest} returns this
*/
proto.satys.datanalysis.GetTasksRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetTasksRequest} returns this
 */
proto.satys.datanalysis.GetTasksRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetTasksRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetTasksResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTasksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTasksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    satys_domain_domain_pb.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTasksResponse}
 */
proto.satys.datanalysis.GetTasksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTasksResponse;
  return proto.satys.datanalysis.GetTasksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTasksResponse}
 */
proto.satys.datanalysis.GetTasksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Task;
      reader.readMessage(value,satys_domain_domain_pb.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.GetTasksResponse} returns this
*/
proto.satys.datanalysis.GetTasksResponse.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetTasksResponse} returns this
 */
proto.satys.datanalysis.GetTasksResponse.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.Task tasks = 2;
 * @return {!Array<!proto.satys.domain.Task>}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.satys.domain.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Task, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Task>} value
 * @return {!proto.satys.datanalysis.GetTasksResponse} returns this
*/
proto.satys.datanalysis.GetTasksResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Task}
 */
proto.satys.datanalysis.GetTasksResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetTasksResponse} returns this
 */
proto.satys.datanalysis.GetTasksResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTasksWithActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsRequest}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTasksWithActionsRequest;
  return proto.satys.datanalysis.GetTasksWithActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsRequest}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTasksWithActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.GetTasksWithActionsRequest} returns this
*/
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsRequest} returns this
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetTasksWithActionsRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTasksWithActionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTasksWithActionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f),
    taskTreesList: jspb.Message.toObjectList(msg.getTaskTreesList(),
    satys_domain_domain_pb.TaskTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTasksWithActionsResponse;
  return proto.satys.datanalysis.GetTasksWithActionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTasksWithActionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.TaskTree;
      reader.readMessage(value,satys_domain_domain_pb.TaskTree.deserializeBinaryFromReader);
      msg.addTaskTrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTasksWithActionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTasksWithActionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
  f = message.getTaskTreesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.TaskTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse} returns this
*/
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse} returns this
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.TaskTree task_trees = 2;
 * @return {!Array<!proto.satys.domain.TaskTree>}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.getTaskTreesList = function() {
  return /** @type{!Array<!proto.satys.domain.TaskTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.TaskTree, 2));
};


/**
 * @param {!Array<!proto.satys.domain.TaskTree>} value
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse} returns this
*/
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.setTaskTreesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.TaskTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.TaskTree}
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.addTaskTrees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.TaskTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetTasksWithActionsResponse} returns this
 */
proto.satys.datanalysis.GetTasksWithActionsResponse.prototype.clearTaskTreesList = function() {
  return this.setTaskTreesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.datanalysis.GetProcessesRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.satys.datanalysis.GetProcessesRequest.OrgProdCase = {
  ORG_PROD_NOT_SET: 0,
  ORGANISATION: 1,
  PRODUCT: 2
};

/**
 * @return {proto.satys.datanalysis.GetProcessesRequest.OrgProdCase}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.getOrgProdCase = function() {
  return /** @type {proto.satys.datanalysis.GetProcessesRequest.OrgProdCase} */(jspb.Message.computeOneofCase(this, proto.satys.datanalysis.GetProcessesRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProcessesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProcessesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProcessesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && satys_domain_domain_pb.Product.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProcessesRequest}
 */
proto.satys.datanalysis.GetProcessesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProcessesRequest;
  return proto.satys.datanalysis.GetProcessesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProcessesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProcessesRequest}
 */
proto.satys.datanalysis.GetProcessesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Product;
      reader.readMessage(value,satys_domain_domain_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProcessesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProcessesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProcessesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetProcessesRequest} returns this
*/
proto.satys.datanalysis.GetProcessesRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.satys.datanalysis.GetProcessesRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProcessesRequest} returns this
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Product product = 2;
 * @return {?proto.satys.domain.Product}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.getProduct = function() {
  return /** @type{?proto.satys.domain.Product} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Product, 2));
};


/**
 * @param {?proto.satys.domain.Product|undefined} value
 * @return {!proto.satys.datanalysis.GetProcessesRequest} returns this
*/
proto.satys.datanalysis.GetProcessesRequest.prototype.setProduct = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.satys.datanalysis.GetProcessesRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProcessesRequest} returns this
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProcessesRequest.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetProcessesResponse.repeatedFields_ = [3];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.datanalysis.GetProcessesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.satys.datanalysis.GetProcessesResponse.OrgProdCase = {
  ORG_PROD_NOT_SET: 0,
  ORGANISATION: 1,
  PRODUCT: 2
};

/**
 * @return {proto.satys.datanalysis.GetProcessesResponse.OrgProdCase}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.getOrgProdCase = function() {
  return /** @type {proto.satys.datanalysis.GetProcessesResponse.OrgProdCase} */(jspb.Message.computeOneofCase(this, proto.satys.datanalysis.GetProcessesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProcessesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProcessesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProcessesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && satys_domain_domain_pb.Product.toObject(includeInstance, f),
    processesList: jspb.Message.toObjectList(msg.getProcessesList(),
    satys_domain_domain_pb.Process.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProcessesResponse}
 */
proto.satys.datanalysis.GetProcessesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProcessesResponse;
  return proto.satys.datanalysis.GetProcessesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProcessesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProcessesResponse}
 */
proto.satys.datanalysis.GetProcessesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Product;
      reader.readMessage(value,satys_domain_domain_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.addProcesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProcessesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProcessesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProcessesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getProcessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
*/
proto.satys.datanalysis.GetProcessesResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.satys.datanalysis.GetProcessesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Product product = 2;
 * @return {?proto.satys.domain.Product}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.getProduct = function() {
  return /** @type{?proto.satys.domain.Product} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Product, 2));
};


/**
 * @param {?proto.satys.domain.Product|undefined} value
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
*/
proto.satys.datanalysis.GetProcessesResponse.prototype.setProduct = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.satys.datanalysis.GetProcessesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated satys.domain.Process processes = 3;
 * @return {!Array<!proto.satys.domain.Process>}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.getProcessesList = function() {
  return /** @type{!Array<!proto.satys.domain.Process>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Process, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Process>} value
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
*/
proto.satys.datanalysis.GetProcessesResponse.prototype.setProcessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Process=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.addProcesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Process, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetProcessesResponse} returns this
 */
proto.satys.datanalysis.GetProcessesResponse.prototype.clearProcessesList = function() {
  return this.setProcessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProductsRequest}
 */
proto.satys.datanalysis.GetProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProductsRequest;
  return proto.satys.datanalysis.GetProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProductsRequest}
 */
proto.satys.datanalysis.GetProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetProductsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetProductsRequest} returns this
*/
proto.satys.datanalysis.GetProductsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProductsRequest} returns this
 */
proto.satys.datanalysis.GetProductsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProductsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetProductsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    satys_domain_domain_pb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProductsResponse}
 */
proto.satys.datanalysis.GetProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProductsResponse;
  return proto.satys.datanalysis.GetProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProductsResponse}
 */
proto.satys.datanalysis.GetProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Product;
      reader.readMessage(value,satys_domain_domain_pb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Product products = 1;
 * @return {!Array<!proto.satys.domain.Product>}
 */
proto.satys.datanalysis.GetProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.satys.domain.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Product, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Product>} value
 * @return {!proto.satys.datanalysis.GetProductsResponse} returns this
*/
proto.satys.datanalysis.GetProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Product}
 */
proto.satys.datanalysis.GetProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetProductsResponse} returns this
 */
proto.satys.datanalysis.GetProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateProductRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateProductRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateProductRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProductRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && satys_domain_domain_pb.Product.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateProductRequest}
 */
proto.satys.datanalysis.CreateProductRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateProductRequest;
  return proto.satys.datanalysis.CreateProductRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateProductRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateProductRequest}
 */
proto.satys.datanalysis.CreateProductRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Product;
      reader.readMessage(value,satys_domain_domain_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateProductRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateProductRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateProductRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProductRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Product product = 1;
 * @return {?proto.satys.domain.Product}
 */
proto.satys.datanalysis.CreateProductRequest.prototype.getProduct = function() {
  return /** @type{?proto.satys.domain.Product} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Product, 1));
};


/**
 * @param {?proto.satys.domain.Product|undefined} value
 * @return {!proto.satys.datanalysis.CreateProductRequest} returns this
*/
proto.satys.datanalysis.CreateProductRequest.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateProductRequest} returns this
 */
proto.satys.datanalysis.CreateProductRequest.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateProductRequest.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateProductResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateProductResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateProductResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProductResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateProductResponse}
 */
proto.satys.datanalysis.CreateProductResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateProductResponse;
  return proto.satys.datanalysis.CreateProductResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateProductResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateProductResponse}
 */
proto.satys.datanalysis.CreateProductResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateProductResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateProductResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateProductResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProductResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.CreateProductResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.CreateProductResponse} returns this
 */
proto.satys.datanalysis.CreateProductResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateOrganisationRequest}
 */
proto.satys.datanalysis.CreateOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateOrganisationRequest;
  return proto.satys.datanalysis.CreateOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateOrganisationRequest}
 */
proto.satys.datanalysis.CreateOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.CreateOrganisationRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.CreateOrganisationRequest} returns this
*/
proto.satys.datanalysis.CreateOrganisationRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateOrganisationRequest} returns this
 */
proto.satys.datanalysis.CreateOrganisationRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateOrganisationRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateOrganisationResponse}
 */
proto.satys.datanalysis.CreateOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateOrganisationResponse;
  return proto.satys.datanalysis.CreateOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateOrganisationResponse}
 */
proto.satys.datanalysis.CreateOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.satys.datanalysis.CreateOrganisationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.datanalysis.CreateOrganisationResponse} returns this
 */
proto.satys.datanalysis.CreateOrganisationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationRequest}
 */
proto.satys.datanalysis.GetOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationRequest;
  return proto.satys.datanalysis.GetOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationRequest}
 */
proto.satys.datanalysis.GetOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationRequest} returns this
*/
proto.satys.datanalysis.GetOrganisationRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationRequest} returns this
 */
proto.satys.datanalysis.GetOrganisationRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationResponse}
 */
proto.satys.datanalysis.GetOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationResponse;
  return proto.satys.datanalysis.GetOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationResponse}
 */
proto.satys.datanalysis.GetOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateOrganisationRequest}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateOrganisationRequest;
  return proto.satys.datanalysis.UpdateOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateOrganisationRequest}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.UpdateOrganisationRequest} returns this
*/
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateOrganisationRequest} returns this
 */
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateOrganisationRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateOrganisationResponse}
 */
proto.satys.datanalysis.UpdateOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateOrganisationResponse;
  return proto.satys.datanalysis.UpdateOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateOrganisationResponse}
 */
proto.satys.datanalysis.UpdateOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetPerformanceIndicatorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    performanceIndicatorsList: jspb.Message.toObjectList(msg.getPerformanceIndicatorsList(),
    satys_domain_domain_pb.PerformanceIndicator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetPerformanceIndicatorsRequest;
  return proto.satys.datanalysis.SetPerformanceIndicatorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.addPerformanceIndicators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetPerformanceIndicatorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} returns this
*/
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} returns this
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.PerformanceIndicator performance_indicators = 2;
 * @return {!Array<!proto.satys.domain.PerformanceIndicator>}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.getPerformanceIndicatorsList = function() {
  return /** @type{!Array<!proto.satys.domain.PerformanceIndicator>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {!Array<!proto.satys.domain.PerformanceIndicator>} value
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} returns this
*/
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.setPerformanceIndicatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.PerformanceIndicator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.addPerformanceIndicators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.PerformanceIndicator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} returns this
 */
proto.satys.datanalysis.SetPerformanceIndicatorsRequest.prototype.clearPerformanceIndicatorsList = function() {
  return this.setPerformanceIndicatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetPerformanceIndicatorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsResponse}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetPerformanceIndicatorsResponse;
  return proto.satys.datanalysis.SetPerformanceIndicatorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetPerformanceIndicatorsResponse}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetPerformanceIndicatorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetPerformanceIndicatorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depth: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationsRequest}
 */
proto.satys.datanalysis.GetOrganisationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationsRequest;
  return proto.satys.datanalysis.GetOrganisationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationsRequest}
 */
proto.satys.datanalysis.GetOrganisationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 depth = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetOrganisationsRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetOrganisationsRequest} returns this
 */
proto.satys.datanalysis.GetOrganisationsRequest.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetOrganisationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationsResponse}
 */
proto.satys.datanalysis.GetOrganisationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationsResponse;
  return proto.satys.datanalysis.GetOrganisationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationsResponse}
 */
proto.satys.datanalysis.GetOrganisationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Organisation organisations = 1;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetOrganisationsResponse.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetOrganisationsResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationsResponse.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationsResponse.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetOrganisationsResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationsResponse.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationParentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsRequest}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationParentsRequest;
  return proto.satys.datanalysis.GetOrganisationParentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsRequest}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationParentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationParentsRequest} returns this
*/
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsRequest} returns this
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationParentsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationParentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationParentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsResponse}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationParentsResponse;
  return proto.satys.datanalysis.GetOrganisationParentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationParentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsResponse}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationParentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationParentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationParentsResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationParentsResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationParentsResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesRequest}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationRolesRequest;
  return proto.satys.datanalysis.GetOrganisationRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesRequest}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationRolesRequest} returns this
*/
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesRequest} returns this
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationRolesRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    satys_domain_domain_pb.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesResponse}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationRolesResponse;
  return proto.satys.datanalysis.GetOrganisationRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesResponse}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Role;
      reader.readMessage(value,satys_domain_domain_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Role roles = 1;
 * @return {!Array<!proto.satys.domain.Role>}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.satys.domain.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Role, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Role>} value
 * @return {!proto.satys.datanalysis.GetOrganisationRolesResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Role}
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetOrganisationRolesResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQueryableOrganisationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsRequest}
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQueryableOrganisationsRequest;
  return proto.satys.datanalysis.GetQueryableOrganisationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsRequest}
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQueryableOrganisationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQueryableOrganisationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQueryableOrganisationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsResponse}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQueryableOrganisationsResponse;
  return proto.satys.datanalysis.GetQueryableOrganisationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsResponse}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQueryableOrganisationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Organisation organisations = 1;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsResponse} returns this
*/
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQueryableOrganisationsResponse} returns this
 */
proto.satys.datanalysis.GetQueryableOrganisationsResponse.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationWithDescendantsRequest;
  return proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} returns this
*/
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} returns this
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.OrganisationWithDescendants.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetOrganisationWithDescendantsResponse;
  return proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.OrganisationWithDescendants;
      reader.readMessage(value,satys_domain_domain_pb.OrganisationWithDescendants.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.OrganisationWithDescendants.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.OrganisationWithDescendants organisation = 1;
 * @return {?proto.satys.domain.OrganisationWithDescendants}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.OrganisationWithDescendants} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.OrganisationWithDescendants, 1));
};


/**
 * @param {?proto.satys.domain.OrganisationWithDescendants|undefined} value
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse} returns this
*/
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse} returns this
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProbacTreeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProbacTreeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProbacTreeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProbacTreeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProbacTreeRequest}
 */
proto.satys.datanalysis.GetProbacTreeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProbacTreeRequest;
  return proto.satys.datanalysis.GetProbacTreeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProbacTreeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProbacTreeRequest}
 */
proto.satys.datanalysis.GetProbacTreeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProbacTreeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProbacTreeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProbacTreeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProbacTreeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetProbacTreeRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetProbacTreeRequest} returns this
*/
proto.satys.datanalysis.GetProbacTreeRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProbacTreeRequest} returns this
 */
proto.satys.datanalysis.GetProbacTreeRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProbacTreeRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetProbacTreeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetProbacTreeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetProbacTreeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProbacTreeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    probacTree: (f = msg.getProbacTree()) && satys_domain_domain_pb.ProbacTree.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetProbacTreeResponse}
 */
proto.satys.datanalysis.GetProbacTreeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetProbacTreeResponse;
  return proto.satys.datanalysis.GetProbacTreeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetProbacTreeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetProbacTreeResponse}
 */
proto.satys.datanalysis.GetProbacTreeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProbacTree;
      reader.readMessage(value,satys_domain_domain_pb.ProbacTree.deserializeBinaryFromReader);
      msg.setProbacTree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetProbacTreeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetProbacTreeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetProbacTreeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetProbacTreeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProbacTree();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProbacTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProbacTree probac_tree = 1;
 * @return {?proto.satys.domain.ProbacTree}
 */
proto.satys.datanalysis.GetProbacTreeResponse.prototype.getProbacTree = function() {
  return /** @type{?proto.satys.domain.ProbacTree} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProbacTree, 1));
};


/**
 * @param {?proto.satys.domain.ProbacTree|undefined} value
 * @return {!proto.satys.datanalysis.GetProbacTreeResponse} returns this
*/
proto.satys.datanalysis.GetProbacTreeResponse.prototype.setProbacTree = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetProbacTreeResponse} returns this
 */
proto.satys.datanalysis.GetProbacTreeResponse.prototype.clearProbacTree = function() {
  return this.setProbacTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetProbacTreeResponse.prototype.hasProbacTree = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMeasurementsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMeasurementsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMeasurementsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMeasurementsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMeasurementsRequest}
 */
proto.satys.datanalysis.GetMeasurementsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMeasurementsRequest;
  return proto.satys.datanalysis.GetMeasurementsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMeasurementsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMeasurementsRequest}
 */
proto.satys.datanalysis.GetMeasurementsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMeasurementsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMeasurementsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMeasurementsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMeasurementsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetMeasurementsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetMeasurementsRequest} returns this
*/
proto.satys.datanalysis.GetMeasurementsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMeasurementsRequest} returns this
 */
proto.satys.datanalysis.GetMeasurementsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMeasurementsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMeasurementsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMeasurementsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMeasurementsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMeasurementsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMeasurementsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMeasurementsResponse}
 */
proto.satys.datanalysis.GetMeasurementsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMeasurementsResponse;
  return proto.satys.datanalysis.GetMeasurementsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMeasurementsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMeasurementsResponse}
 */
proto.satys.datanalysis.GetMeasurementsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMeasurementsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMeasurementsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMeasurementsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMeasurementsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Measurement measurements = 1;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetMeasurementsResponse.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetMeasurementsResponse} returns this
*/
proto.satys.datanalysis.GetMeasurementsResponse.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetMeasurementsResponse.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMeasurementsResponse} returns this
 */
proto.satys.datanalysis.GetMeasurementsResponse.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateMeasurementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateMeasurementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateMeasurementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateMeasurementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    measurement: (f = msg.getMeasurement()) && satys_domain_domain_pb.Measurement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateMeasurementRequest}
 */
proto.satys.datanalysis.CreateMeasurementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateMeasurementRequest;
  return proto.satys.datanalysis.CreateMeasurementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateMeasurementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateMeasurementRequest}
 */
proto.satys.datanalysis.CreateMeasurementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.setMeasurement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateMeasurementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateMeasurementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateMeasurementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateMeasurementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasurement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Measurement measurement = 1;
 * @return {?proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.CreateMeasurementRequest.prototype.getMeasurement = function() {
  return /** @type{?proto.satys.domain.Measurement} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Measurement, 1));
};


/**
 * @param {?proto.satys.domain.Measurement|undefined} value
 * @return {!proto.satys.datanalysis.CreateMeasurementRequest} returns this
*/
proto.satys.datanalysis.CreateMeasurementRequest.prototype.setMeasurement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateMeasurementRequest} returns this
 */
proto.satys.datanalysis.CreateMeasurementRequest.prototype.clearMeasurement = function() {
  return this.setMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateMeasurementRequest.prototype.hasMeasurement = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateMeasurementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateMeasurementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateMeasurementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateMeasurementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateMeasurementResponse}
 */
proto.satys.datanalysis.CreateMeasurementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateMeasurementResponse;
  return proto.satys.datanalysis.CreateMeasurementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateMeasurementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateMeasurementResponse}
 */
proto.satys.datanalysis.CreateMeasurementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateMeasurementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateMeasurementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateMeasurementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateMeasurementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnalysesRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnalysesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnalysesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnalysesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest}
 */
proto.satys.datanalysis.GetAnalysesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnalysesRequest;
  return proto.satys.datanalysis.GetAnalysesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnalysesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest}
 */
proto.satys.datanalysis.GetAnalysesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnalysesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnalysesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnalysesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Organisation organisations = 1;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
*/
proto.satys.datanalysis.GetAnalysesRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 2;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
*/
proto.satys.datanalysis.GetAnalysesRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
*/
proto.satys.datanalysis.GetAnalysesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
*/
proto.satys.datanalysis.GetAnalysesRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnalysesRequest} returns this
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnalysesRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnalysesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnalysesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnalysesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnalysesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnalysesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    analysesList: jspb.Message.toObjectList(msg.getAnalysesList(),
    satys_domain_domain_pb.Analysis.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnalysesResponse}
 */
proto.satys.datanalysis.GetAnalysesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnalysesResponse;
  return proto.satys.datanalysis.GetAnalysesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnalysesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnalysesResponse}
 */
proto.satys.datanalysis.GetAnalysesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Analysis;
      reader.readMessage(value,satys_domain_domain_pb.Analysis.deserializeBinaryFromReader);
      msg.addAnalyses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnalysesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnalysesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnalysesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnalysesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalysesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Analysis.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Analysis analyses = 1;
 * @return {!Array<!proto.satys.domain.Analysis>}
 */
proto.satys.datanalysis.GetAnalysesResponse.prototype.getAnalysesList = function() {
  return /** @type{!Array<!proto.satys.domain.Analysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Analysis, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Analysis>} value
 * @return {!proto.satys.datanalysis.GetAnalysesResponse} returns this
*/
proto.satys.datanalysis.GetAnalysesResponse.prototype.setAnalysesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Analysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Analysis}
 */
proto.satys.datanalysis.GetAnalysesResponse.prototype.addAnalyses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Analysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnalysesResponse} returns this
 */
proto.satys.datanalysis.GetAnalysesResponse.prototype.clearAnalysesList = function() {
  return this.setAnalysesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateAnalysisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateAnalysisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateAnalysisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateAnalysisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    analysis: (f = msg.getAnalysis()) && satys_domain_domain_pb.Analysis.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateAnalysisRequest}
 */
proto.satys.datanalysis.CreateAnalysisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateAnalysisRequest;
  return proto.satys.datanalysis.CreateAnalysisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateAnalysisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateAnalysisRequest}
 */
proto.satys.datanalysis.CreateAnalysisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Analysis;
      reader.readMessage(value,satys_domain_domain_pb.Analysis.deserializeBinaryFromReader);
      msg.setAnalysis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateAnalysisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateAnalysisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateAnalysisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateAnalysisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalysis();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Analysis.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Analysis analysis = 1;
 * @return {?proto.satys.domain.Analysis}
 */
proto.satys.datanalysis.CreateAnalysisRequest.prototype.getAnalysis = function() {
  return /** @type{?proto.satys.domain.Analysis} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Analysis, 1));
};


/**
 * @param {?proto.satys.domain.Analysis|undefined} value
 * @return {!proto.satys.datanalysis.CreateAnalysisRequest} returns this
*/
proto.satys.datanalysis.CreateAnalysisRequest.prototype.setAnalysis = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateAnalysisRequest} returns this
 */
proto.satys.datanalysis.CreateAnalysisRequest.prototype.clearAnalysis = function() {
  return this.setAnalysis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateAnalysisRequest.prototype.hasAnalysis = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateAnalysisResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateAnalysisResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateAnalysisResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateAnalysisResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateAnalysisResponse}
 */
proto.satys.datanalysis.CreateAnalysisResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateAnalysisResponse;
  return proto.satys.datanalysis.CreateAnalysisResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateAnalysisResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateAnalysisResponse}
 */
proto.satys.datanalysis.CreateAnalysisResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateAnalysisResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateAnalysisResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateAnalysisResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateAnalysisResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetQuestionnairesRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnairesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnairesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnairesRequest;
  return proto.satys.datanalysis.GetQuestionnairesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnairesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnairesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Measurement measurements = 1;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 2;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionnairesRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionnairesRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetQuestionnairesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnairesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnairesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnairesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnairesResponse}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnairesResponse;
  return proto.satys.datanalysis.GetQuestionnairesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnairesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnairesResponse}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnairesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnairesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnairesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 1;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetQuestionnairesResponse} returns this
*/
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionnairesResponse} returns this
 */
proto.satys.datanalysis.GetQuestionnairesResponse.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnaireRequest}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnaireRequest;
  return proto.satys.datanalysis.GetQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnaireRequest}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionnaireRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionnaireRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionnaireRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnaireResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnaireResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnaireResponse}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnaireResponse;
  return proto.satys.datanalysis.GetQuestionnaireResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnaireResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnaireResponse}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnaireResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnaireResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionnaireResponse} returns this
*/
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionnaireResponse} returns this
 */
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionnaireResponse.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnaireUrlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnaireUrlsRequest;
  return proto.satys.datanalysis.GetQuestionnaireUrlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnaireUrlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} returns this
*/
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} returns this
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionnaireUrlsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    editUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionnaireUrlsResponse;
  return proto.satys.datanalysis.GetQuestionnaireUrlsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionnaireUrlsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEditUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string view_url = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.getViewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse} returns this
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.setViewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edit_url = 2;
 * @return {string}
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.getEditUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetQuestionnaireUrlsResponse} returns this
 */
proto.satys.datanalysis.GetQuestionnaireUrlsResponse.prototype.setEditUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f),
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateQuestionnaireRequest}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateQuestionnaireRequest;
  return proto.satys.datanalysis.CreateQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateQuestionnaireRequest}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional satys.domain.Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.CreateQuestionnaireRequest} returns this
*/
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateQuestionnaireRequest} returns this
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.CreateQuestionnaireRequest} returns this
 */
proto.satys.datanalysis.CreateQuestionnaireRequest.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateQuestionnaireResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateQuestionnaireResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateQuestionnaireResponse}
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateQuestionnaireResponse;
  return proto.satys.datanalysis.CreateQuestionnaireResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateQuestionnaireResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateQuestionnaireResponse}
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateQuestionnaireResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateQuestionnaireResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionnaireResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldQuestionnaire: (f = msg.getOldQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f),
    newQuestionnaire: (f = msg.getNewQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateQuestionnaireRequest;
  return proto.satys.datanalysis.UpdateQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setOldQuestionnaire(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setNewQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getNewQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Questionnaire old_questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.getOldQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest} returns this
*/
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.setOldQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest} returns this
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.clearOldQuestionnaire = function() {
  return this.setOldQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.hasOldQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Questionnaire new_questionnaire = 2;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.getNewQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest} returns this
*/
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.setNewQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireRequest} returns this
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.clearNewQuestionnaire = function() {
  return this.setNewQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateQuestionnaireRequest.prototype.hasNewQuestionnaire = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateQuestionnaireResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireResponse}
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateQuestionnaireResponse;
  return proto.satys.datanalysis.UpdateQuestionnaireResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateQuestionnaireResponse}
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateQuestionnaireResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionnaireResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetQuestionsRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionsRequest}
 */
proto.satys.datanalysis.GetQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionsRequest;
  return proto.satys.datanalysis.GetQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionsRequest}
 */
proto.satys.datanalysis.GetQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
*/
proto.satys.datanalysis.GetQuestionsRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
*/
proto.satys.datanalysis.GetQuestionsRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
*/
proto.satys.datanalysis.GetQuestionsRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionsRequest} returns this
 */
proto.satys.datanalysis.GetQuestionsRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetQuestionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionsResponse}
 */
proto.satys.datanalysis.GetQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionsResponse;
  return proto.satys.datanalysis.GetQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionsResponse}
 */
proto.satys.datanalysis.GetQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Question questions = 2;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetQuestionsResponse.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetQuestionsResponse} returns this
*/
proto.satys.datanalysis.GetQuestionsResponse.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetQuestionsResponse.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetQuestionsResponse} returns this
 */
proto.satys.datanalysis.GetQuestionsResponse.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionRequest}
 */
proto.satys.datanalysis.GetQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionRequest;
  return proto.satys.datanalysis.GetQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionRequest}
 */
proto.satys.datanalysis.GetQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Question question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetQuestionRequest.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionRequest} returns this
*/
proto.satys.datanalysis.GetQuestionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionRequest} returns this
 */
proto.satys.datanalysis.GetQuestionRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetQuestionResponse}
 */
proto.satys.datanalysis.GetQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetQuestionResponse;
  return proto.satys.datanalysis.GetQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetQuestionResponse}
 */
proto.satys.datanalysis.GetQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Question question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetQuestionResponse.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.GetQuestionResponse} returns this
*/
proto.satys.datanalysis.GetQuestionResponse.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetQuestionResponse} returns this
 */
proto.satys.datanalysis.GetQuestionResponse.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetQuestionResponse.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateQuestionRequest}
 */
proto.satys.datanalysis.CreateQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateQuestionRequest;
  return proto.satys.datanalysis.CreateQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateQuestionRequest}
 */
proto.satys.datanalysis.CreateQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Question question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.CreateQuestionRequest.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.CreateQuestionRequest} returns this
*/
proto.satys.datanalysis.CreateQuestionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateQuestionRequest} returns this
 */
proto.satys.datanalysis.CreateQuestionRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateQuestionRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateQuestionResponse}
 */
proto.satys.datanalysis.CreateQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateQuestionResponse;
  return proto.satys.datanalysis.CreateQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateQuestionResponse}
 */
proto.satys.datanalysis.CreateQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldQuestion: (f = msg.getOldQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f),
    newQuestion: (f = msg.getNewQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest}
 */
proto.satys.datanalysis.UpdateQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateQuestionRequest;
  return proto.satys.datanalysis.UpdateQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest}
 */
proto.satys.datanalysis.UpdateQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setOldQuestion(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setNewQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getNewQuestion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Question old_question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.getOldQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest} returns this
*/
proto.satys.datanalysis.UpdateQuestionRequest.prototype.setOldQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest} returns this
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.clearOldQuestion = function() {
  return this.setOldQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.hasOldQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Question new_question = 2;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.getNewQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 2));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest} returns this
*/
proto.satys.datanalysis.UpdateQuestionRequest.prototype.setNewQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UpdateQuestionRequest} returns this
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.clearNewQuestion = function() {
  return this.setNewQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UpdateQuestionRequest.prototype.hasNewQuestion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UpdateQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UpdateQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UpdateQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UpdateQuestionResponse}
 */
proto.satys.datanalysis.UpdateQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UpdateQuestionResponse;
  return proto.satys.datanalysis.UpdateQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UpdateQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UpdateQuestionResponse}
 */
proto.satys.datanalysis.UpdateQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UpdateQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UpdateQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UpdateQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UpdateQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: msg.getContent_asB64(),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest;
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes content = 1;
 * @return {!(string|Uint8Array)}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes content = 1;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse;
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_uri = 1;
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.prototype.getFileUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetResponse.prototype.setFileUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: msg.getContent_asB64(),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest;
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes content = 1;
 * @return {!(string|Uint8Array)}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes content = 1;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse;
  return proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_uri = 1;
 * @return {string}
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.prototype.getFileUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse} returns this
 */
proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.prototype.setFileUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaireId: (f = msg.getQuestionnaireId()) && satys_domain_domain_pb.QuestionnaireId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest;
  return proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.QuestionnaireId;
      reader.readMessage(value,satys_domain_domain_pb.QuestionnaireId.deserializeBinaryFromReader);
      msg.setQuestionnaireId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaireId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.QuestionnaireId.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.QuestionnaireId questionnaire_id = 1;
 * @return {?proto.satys.domain.QuestionnaireId}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.getQuestionnaireId = function() {
  return /** @type{?proto.satys.domain.QuestionnaireId} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.QuestionnaireId, 1));
};


/**
 * @param {?proto.satys.domain.QuestionnaireId|undefined} value
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest} returns this
*/
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.setQuestionnaireId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest} returns this
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.clearQuestionnaireId = function() {
  return this.setQuestionnaireId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdRequest.prototype.hasQuestionnaireId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse;
  return proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string integration_uid = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.getIntegrationUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse} returns this
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.setIntegrationUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse} returns this
 */
proto.satys.datanalysis.GetIntegrationUidAndThirdPartyByQuestionnaireIdResponse.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateProcessFromBpmnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f),
    xml: msg.getXml_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnRequest}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateProcessFromBpmnRequest;
  return proto.satys.datanalysis.CreateProcessFromBpmnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnRequest}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setXml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateProcessFromBpmnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
  f = message.getXml_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} returns this
*/
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} returns this
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes xml = 2;
 * @return {!(string|Uint8Array)}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.getXml = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes xml = 2;
 * This is a type-conversion wrapper around `getXml()`
 * @return {string}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.getXml_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getXml()));
};


/**
 * optional bytes xml = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getXml()`
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.getXml_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getXml()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} returns this
 */
proto.satys.datanalysis.CreateProcessFromBpmnRequest.prototype.setXml = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateProcessFromBpmnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnResponse}
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateProcessFromBpmnResponse;
  return proto.satys.datanalysis.CreateProcessFromBpmnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateProcessFromBpmnResponse}
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateProcessFromBpmnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateProcessFromBpmnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetBpmnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetBpmnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetBpmnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetBpmnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetBpmnRequest}
 */
proto.satys.datanalysis.GetBpmnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetBpmnRequest;
  return proto.satys.datanalysis.GetBpmnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetBpmnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetBpmnRequest}
 */
proto.satys.datanalysis.GetBpmnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetBpmnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetBpmnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetBpmnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetBpmnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.datanalysis.GetBpmnRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.datanalysis.GetBpmnRequest} returns this
*/
proto.satys.datanalysis.GetBpmnRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetBpmnRequest} returns this
 */
proto.satys.datanalysis.GetBpmnRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetBpmnRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetBpmnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetBpmnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetBpmnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetBpmnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetBpmnResponse}
 */
proto.satys.datanalysis.GetBpmnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetBpmnResponse;
  return proto.satys.datanalysis.GetBpmnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetBpmnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetBpmnResponse}
 */
proto.satys.datanalysis.GetBpmnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetBpmnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetBpmnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetBpmnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetBpmnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetBpmnResponse.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetBpmnResponse} returns this
 */
proto.satys.datanalysis.GetBpmnResponse.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetResponsesRangeRequest.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetResponsesRangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesRangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetResponsesRangeRequest;
  return proto.satys.datanalysis.GetResponsesRangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 5:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetResponsesRangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesRangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 1;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
*/
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 2;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
*/
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 3;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
*/
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated satys.domain.Label labels = 4;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
*/
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 5;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 5));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
*/
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesRangeRequest} returns this
 */
proto.satys.datanalysis.GetResponsesRangeRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetResponsesRangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetResponsesRangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesRangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetResponsesRangeResponse;
  return proto.satys.datanalysis.GetResponsesRangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetResponsesRangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetResponsesRangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetResponsesRangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesRangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date_from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse} returns this
*/
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse} returns this
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse} returns this
*/
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetResponsesRangeResponse} returns this
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetResponsesRangeResponse.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetResponsesCountRequest.repeatedFields_ = [1,2,3,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetResponsesCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetResponsesCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest}
 */
proto.satys.datanalysis.GetResponsesCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetResponsesCountRequest;
  return proto.satys.datanalysis.GetResponsesCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetResponsesCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest}
 */
proto.satys.datanalysis.GetResponsesCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 7:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetResponsesCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetResponsesCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 1;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 2;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 3;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated satys.domain.Label labels = 7;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 7));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 8;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 8));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
*/
proto.satys.datanalysis.GetResponsesCountRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetResponsesCountRequest} returns this
 */
proto.satys.datanalysis.GetResponsesCountRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetResponsesCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsesCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    typical: (f = msg.getTypical()) && proto.satys.datanalysis.GetResponsesCountResponse.Typical.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse}
 */
proto.satys.datanalysis.GetResponsesCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetResponsesCountResponse;
  return proto.satys.datanalysis.GetResponsesCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse}
 */
proto.satys.datanalysis.GetResponsesCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResponsesCount(value);
      break;
    case 2:
      var value = new proto.satys.datanalysis.GetResponsesCountResponse.Typical;
      reader.readMessage(value,proto.satys.datanalysis.GetResponsesCountResponse.Typical.deserializeBinaryFromReader);
      msg.setTypical(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetResponsesCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsesCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTypical();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.datanalysis.GetResponsesCountResponse.Typical.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetResponsesCountResponse.Typical.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse.Typical} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.toObject = function(includeInstance, msg) {
  var f, obj = {
    lower: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upper: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse.Typical}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetResponsesCountResponse.Typical;
  return proto.satys.datanalysis.GetResponsesCountResponse.Typical.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse.Typical} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse.Typical}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLower(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetResponsesCountResponse.Typical.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetResponsesCountResponse.Typical} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLower();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float lower = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.getLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse.Typical} returns this
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.setLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.getUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse.Typical} returns this
 */
proto.satys.datanalysis.GetResponsesCountResponse.Typical.prototype.setUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 responses_count = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.getResponsesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse} returns this
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.setResponsesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Typical typical = 2;
 * @return {?proto.satys.datanalysis.GetResponsesCountResponse.Typical}
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.getTypical = function() {
  return /** @type{?proto.satys.datanalysis.GetResponsesCountResponse.Typical} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetResponsesCountResponse.Typical, 2));
};


/**
 * @param {?proto.satys.datanalysis.GetResponsesCountResponse.Typical|undefined} value
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse} returns this
*/
proto.satys.datanalysis.GetResponsesCountResponse.prototype.setTypical = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetResponsesCountResponse} returns this
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.clearTypical = function() {
  return this.setTypical(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetResponsesCountResponse.prototype.hasTypical = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersCountRequest.repeatedFields_ = [10,1,2,3,4,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 9, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest}
 */
proto.satys.datanalysis.GetAnswersCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersCountRequest;
  return proto.satys.datanalysis.GetAnswersCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest}
 */
proto.satys.datanalysis.GetAnswersCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 9:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 11:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 12:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 10;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 9;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated satys.domain.Label labels = 11;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 12;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 12));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
*/
proto.satys.datanalysis.GetAnswersCountRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountRequest} returns this
 */
proto.satys.datanalysis.GetAnswersCountRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersCountResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalResponses: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse}
 */
proto.satys.datanalysis.GetAnswersCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersCountResponse;
  return proto.satys.datanalysis.GetAnswersCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse}
 */
proto.satys.datanalysis.GetAnswersCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalResponses(value);
      break;
    case 2:
      var value = new proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount;
      reader.readMessage(value,proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalResponses();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount;
  return proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount} returns this
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount} returns this
 */
proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse} returns this
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_responses = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.getTotalResponses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse} returns this
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.setTotalResponses = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AnswerCount answers = 2;
 * @return {!Array<!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount>}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount, 2));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount>} value
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse} returns this
*/
proto.satys.datanalysis.GetAnswersCountResponse.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount}
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.datanalysis.GetAnswersCountResponse.AnswerCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersCountResponse} returns this
 */
proto.satys.datanalysis.GetAnswersCountResponse.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersRequest.repeatedFields_ = [9,1,2,3,4,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 8, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersRequest}
 */
proto.satys.datanalysis.GetAnswersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersRequest;
  return proto.satys.datanalysis.GetAnswersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersRequest}
 */
proto.satys.datanalysis.GetAnswersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 8:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 11:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 9;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 8;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated satys.domain.Label labels = 10;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 11;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
*/
proto.satys.datanalysis.GetAnswersRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersRequest} returns this
 */
proto.satys.datanalysis.GetAnswersRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersResponse}
 */
proto.satys.datanalysis.GetAnswersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersResponse;
  return proto.satys.datanalysis.GetAnswersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersResponse}
 */
proto.satys.datanalysis.GetAnswersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 1;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetAnswersResponse.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetAnswersResponse} returns this
*/
proto.satys.datanalysis.GetAnswersResponse.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetAnswersResponse.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersResponse} returns this
 */
proto.satys.datanalysis.GetAnswersResponse.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.repeatedFields_ = [9,1,2,3,4,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersWithSentimentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 8, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersWithSentimentRequest;
  return proto.satys.datanalysis.GetAnswersWithSentimentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 8:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 11:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersWithSentimentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 9;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 8;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated satys.domain.Label labels = 10;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 11;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersWithSentimentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersWithSentimentResponse;
  return proto.satys.datanalysis.GetAnswersWithSentimentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment;
      reader.readMessage(value,proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersWithSentimentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: (f = msg.getAnswer()) && satys_domain_domain_pb.Answer.toObject(includeInstance, f),
    submittedAt: (f = msg.getSubmittedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentiment: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment;
  return proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubmittedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentiment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getSubmittedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentiment();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional satys.domain.Answer answer = 1;
 * @return {?proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.getAnswer = function() {
  return /** @type{?proto.satys.domain.Answer} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Answer, 1));
};


/**
 * @param {?proto.satys.domain.Answer|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp submitted_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.getSubmittedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.setSubmittedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.clearSubmittedAt = function() {
  return this.setSubmittedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.hasSubmittedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 sentiment = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.getSentiment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment.prototype.setSentiment = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AnswerWithSentiment answers = 1;
 * @return {!Array<!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment>}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment>} value
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse} returns this
*/
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment}
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetAnswersWithSentimentResponse.AnswerWithSentiment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetAnswersWithSentimentResponse} returns this
 */
proto.satys.datanalysis.GetAnswersWithSentimentResponse.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest;
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Question question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated satys.domain.Organisation organisations = 2;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f),
    organisationAnswerCountsList: jspb.Message.toObjectList(msg.getOrganisationAnswerCountsList(),
    proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse;
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 2:
      var value = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount;
      reader.readMessage(value,proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.deserializeBinaryFromReader);
      msg.addOrganisationAnswerCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationAnswerCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalResponses: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerCountsList: jspb.Message.toObjectList(msg.getAnswerCountsList(),
    proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.toObject, includeInstance),
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount;
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalResponses(value);
      break;
    case 3:
      var value = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount;
      reader.readMessage(value,proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.deserializeBinaryFromReader);
      msg.addAnswerCounts(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalResponses();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAnswerCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.serializeBinaryToWriter
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_responses = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.getTotalResponses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.setTotalResponses = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AnswerCount answer_counts = 3;
 * @return {!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount>}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.getAnswerCountsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount, 3));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount>} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.setAnswerCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.addAnswerCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.clearAnswerCountsList = function() {
  return this.setAnswerCountsList([]);
};


/**
 * optional satys.domain.Organisation organisation = 4;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount;
  return proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.AnswerCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional satys.domain.Question question = 1;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OrganisationAnswerCount organisation_answer_counts = 2;
 * @return {!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount>}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.getOrganisationAnswerCountsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount, 2));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount>} value
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} returns this
*/
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.setOrganisationAnswerCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount}
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.addOrganisationAnswerCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.OrganisationAnswerCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse} returns this
 */
proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.prototype.clearOrganisationAnswerCountsList = function() {
  return this.setOrganisationAnswerCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetSuborganisationRankingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    amountToRank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 5, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetSuborganisationRankingsRequest;
  return proto.satys.datanalysis.GetSuborganisationRankingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountToRank(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 5:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 6:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 7:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetSuborganisationRankingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getAmountToRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 amount_to_rank = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getAmountToRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setAmountToRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 5;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated satys.domain.Label labels = 6;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 6));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 7;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 7));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 8;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 8));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetSuborganisationRankingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankedOrganisationsList: jspb.Message.toObjectList(msg.getRankedOrganisationsList(),
    proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetSuborganisationRankingsResponse;
  return proto.satys.datanalysis.GetSuborganisationRankingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation;
      reader.readMessage(value,proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.deserializeBinaryFromReader);
      msg.addRankedOrganisations(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetSuborganisationRankingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankedOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation;
  return proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional satys.domain.Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 rank = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 count = 4;
 * @return {number}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated RankedOrganisation ranked_organisations = 3;
 * @return {!Array<!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation>}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.getRankedOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation, 3));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation>} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.setRankedOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.addRankedOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.datanalysis.GetSuborganisationRankingsResponse.RankedOrganisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.clearRankedOrganisationsList = function() {
  return this.setRankedOrganisationsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 4;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 4));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} returns this
*/
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetSuborganisationRankingsResponse} returns this
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetSuborganisationRankingsResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetLabelRankingRequest.repeatedFields_ = [2,3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetLabelRankingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetLabelRankingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelScope: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 6, 0),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest}
 */
proto.satys.datanalysis.GetLabelRankingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetLabelRankingRequest;
  return proto.satys.datanalysis.GetLabelRankingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetLabelRankingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest}
 */
proto.satys.datanalysis.GetLabelRankingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelScope(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 6:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 7:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetLabelRankingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetLabelRankingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelScope();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label_scope = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getLabelScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setLabelScope = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated satys.domain.Organisation organisations = 2;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated satys.domain.Label labels = 3;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 6;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated satys.domain.Channel channels = 7;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 7));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 8;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 8));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
*/
proto.satys.datanalysis.GetLabelRankingRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingRequest} returns this
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetLabelRankingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankedLabel: (f = msg.getRankedLabel()) && proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.toObject(includeInstance, f),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse}
 */
proto.satys.datanalysis.GetLabelRankingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetLabelRankingResponse;
  return proto.satys.datanalysis.GetLabelRankingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse}
 */
proto.satys.datanalysis.GetLabelRankingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel;
      reader.readMessage(value,proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.deserializeBinaryFromReader);
      msg.setRankedLabel(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetLabelRankingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankedLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && satys_domain_domain_pb.Label.toObject(includeInstance, f),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel;
  return proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional satys.domain.Label label = 1;
 * @return {?proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.getLabel = function() {
  return /** @type{?proto.satys.domain.Label} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Label, 1));
};


/**
 * @param {?proto.satys.domain.Label|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} returns this
*/
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 rank = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 count = 4;
 * @return {number}
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional RankedLabel ranked_label = 1;
 * @return {?proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.getRankedLabel = function() {
  return /** @type{?proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel, 1));
};


/**
 * @param {?proto.satys.datanalysis.GetLabelRankingResponse.RankedLabel|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse} returns this
*/
proto.satys.datanalysis.GetLabelRankingResponse.prototype.setRankedLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.clearRankedLabel = function() {
  return this.setRankedLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.hasRankedLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse} returns this
*/
proto.satys.datanalysis.GetLabelRankingResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetLabelRankingResponse} returns this
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetLabelRankingResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetChannelRankingRequest.repeatedFields_ = [2,3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetChannelRankingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetChannelRankingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 6, 0),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest}
 */
proto.satys.datanalysis.GetChannelRankingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetChannelRankingRequest;
  return proto.satys.datanalysis.GetChannelRankingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetChannelRankingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest}
 */
proto.satys.datanalysis.GetChannelRankingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelType(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 6:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 7:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetChannelRankingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetChannelRankingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_type = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getChannelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setChannelType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated satys.domain.Organisation organisations = 2;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated satys.domain.Label labels = 3;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 6;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated satys.domain.Channel channels = 7;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 7));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 8;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 8));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
*/
proto.satys.datanalysis.GetChannelRankingRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingRequest} returns this
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetChannelRankingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankedChannel: (f = msg.getRankedChannel()) && proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.toObject(includeInstance, f),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse}
 */
proto.satys.datanalysis.GetChannelRankingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetChannelRankingResponse;
  return proto.satys.datanalysis.GetChannelRankingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse}
 */
proto.satys.datanalysis.GetChannelRankingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel;
      reader.readMessage(value,proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.deserializeBinaryFromReader);
      msg.setRankedChannel(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetChannelRankingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankedChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && satys_domain_domain_pb.Channel.toObject(includeInstance, f),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel;
  return proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional satys.domain.Channel channel = 1;
 * @return {?proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.getChannel = function() {
  return /** @type{?proto.satys.domain.Channel} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Channel, 1));
};


/**
 * @param {?proto.satys.domain.Channel|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} returns this
*/
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 rank = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 count = 4;
 * @return {number}
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional RankedChannel ranked_channel = 1;
 * @return {?proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.getRankedChannel = function() {
  return /** @type{?proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel, 1));
};


/**
 * @param {?proto.satys.datanalysis.GetChannelRankingResponse.RankedChannel|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse} returns this
*/
proto.satys.datanalysis.GetChannelRankingResponse.prototype.setRankedChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.clearRankedChannel = function() {
  return this.setRankedChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.hasRankedChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse} returns this
*/
proto.satys.datanalysis.GetChannelRankingResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetChannelRankingResponse} returns this
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetChannelRankingResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetRatingStatsRequest.repeatedFields_ = [11,1,2,3,4,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 9, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest}
 */
proto.satys.datanalysis.GetRatingStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsRequest;
  return proto.satys.datanalysis.GetRatingStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest}
 */
proto.satys.datanalysis.GetRatingStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 9:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 12:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 13:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 14:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 11;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 9;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated satys.domain.Label labels = 12;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 12));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 13;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 13));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 14;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 14));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 5, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f),
    typical: (f = msg.getTypical()) && proto.satys.datanalysis.GetRatingStatsResponse.Typical.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse}
 */
proto.satys.datanalysis.GetRatingStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsResponse;
  return proto.satys.datanalysis.GetRatingStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse}
 */
proto.satys.datanalysis.GetRatingStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    case 9:
      var value = new proto.satys.datanalysis.GetRatingStatsResponse.Typical;
      reader.readMessage(value,proto.satys.datanalysis.GetRatingStatsResponse.Typical.deserializeBinaryFromReader);
      msg.setTypical(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
  f = message.getTypical();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.satys.datanalysis.GetRatingStatsResponse.Typical.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsResponse.Typical.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse.Typical} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.toObject = function(includeInstance, msg) {
  var f, obj = {
    lower: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upper: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse.Typical}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsResponse.Typical;
  return proto.satys.datanalysis.GetRatingStatsResponse.Typical.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse.Typical} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse.Typical}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLower(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsResponse.Typical.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsResponse.Typical} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLower();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float lower = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.getLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse.Typical} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.setLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.getUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse.Typical} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.Typical.prototype.setUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 count = 5;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float score = 7;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 8;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 8));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
*/
proto.satys.datanalysis.GetRatingStatsResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Typical typical = 9;
 * @return {?proto.satys.datanalysis.GetRatingStatsResponse.Typical}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.getTypical = function() {
  return /** @type{?proto.satys.datanalysis.GetRatingStatsResponse.Typical} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetRatingStatsResponse.Typical, 9));
};


/**
 * @param {?proto.satys.datanalysis.GetRatingStatsResponse.Typical|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
*/
proto.satys.datanalysis.GetRatingStatsResponse.prototype.setTypical = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.clearTypical = function() {
  return this.setTypical(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsResponse.prototype.hasTypical = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.repeatedFields_ = [11,1,2,3,4,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 9, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsTimeseriesRequest;
  return proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 9:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 12:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 13:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 14:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 11;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 9;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated satys.domain.Label labels = 12;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 12));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 13;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 13));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 14;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 14));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingStatsList: jspb.Message.toObjectList(msg.getRatingStatsList(),
    proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse;
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats;
      reader.readMessage(value,proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.deserializeBinaryFromReader);
      msg.addRatingStats(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 6, 0),
    range: (f = msg.getRange()) && proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats;
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 8:
      var value = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange;
      reader.readMessage(value,proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 6;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DateRange range = 8;
 * @return {?proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.getRange = function() {
  return /** @type{?proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange, 8));
};


/**
 * @param {?proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.hasRange = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float score = 9;
 * @return {number}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    until: (f = msg.getUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange;
  return proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp until = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.getUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.DateRange.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RatingStats rating_stats = 1;
 * @return {!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.getRatingStatsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.setRatingStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.addRatingStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.clearRatingStatsList = function() {
  return this.setRatingStatsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} returns this
*/
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse} returns this
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest;
  return proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date_from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 3;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 3));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
*/
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} returns this
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingStatsList: jspb.Message.toObjectList(msg.getRatingStatsList(),
    proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse;
  return proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats;
      reader.readMessage(value,proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.deserializeBinaryFromReader);
      msg.addRatingStats(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetRatingStatsTimeseriesResponse.RatingStats rating_stats = 1;
 * @return {!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.getRatingStatsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats>} value
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} returns this
*/
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.setRatingStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.addRatingStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.RatingStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} returns this
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.clearRatingStatsList = function() {
  return this.setRatingStatsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} returns this
*/
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse} returns this
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetPriorityStatsRequest.repeatedFields_ = [9,1,2,3,4,8,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetPriorityStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 7, 0),
    subjectsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f),
    strategy: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetPriorityStatsRequest;
  return proto.satys.datanalysis.GetPriorityStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 1:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 7:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubjects(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 11:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 12:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    case 13:
      var value = /** @type {!proto.satys.datanalysis.GetPriorityStatsRequest.Strategy} */ (reader.readEnum());
      msg.setStrategy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetPriorityStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
  f = message.getStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.Strategy = {
  STRATEGY_UNSPECIFIED: 0,
  STRATEGY_CORRELATION: 1,
  STRATEGY_BEST_AND_WORST: 2
};

/**
 * repeated satys.domain.Answer answers = 9;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 1;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 2;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 3;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 4;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 7;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated string subjects = 8;
 * @return {!Array<string>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getSubjectsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setSubjectsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addSubjects = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};


/**
 * repeated satys.domain.Label labels = 10;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 11;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 12;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 12));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
*/
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Strategy strategy = 13;
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest.Strategy}
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.getStrategy = function() {
  return /** @type {!proto.satys.datanalysis.GetPriorityStatsRequest.Strategy} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest.Strategy} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsRequest} returns this
 */
proto.satys.datanalysis.GetPriorityStatsRequest.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetPriorityStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetPriorityStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    priorityStatsList: jspb.Message.toObjectList(msg.getPriorityStatsList(),
    proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetPriorityStatsResponse;
  return proto.satys.datanalysis.GetPriorityStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats;
      reader.readMessage(value,proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.deserializeBinaryFromReader);
      msg.addPriorityStats(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetPriorityStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriorityStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    impact: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    weightedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats;
  return proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpact(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightedScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getImpact();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getWeightedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float impact = 4;
 * @return {number}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.getImpact = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.setImpact = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float weighted_score = 5;
 * @return {number}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.getWeightedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats.prototype.setWeightedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated PriorityStats priority_stats = 1;
 * @return {!Array<!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats>}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.getPriorityStatsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats>} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse} returns this
*/
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.setPriorityStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.addPriorityStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetPriorityStatsResponse.PriorityStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.clearPriorityStatsList = function() {
  return this.setPriorityStatsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse} returns this
*/
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPriorityStatsResponse} returns this
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPriorityStatsResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetCohortStatsRequest.repeatedFields_ = [1,2,3,4,5,9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetCohortStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetCohortStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metric: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cohortsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest}
 */
proto.satys.datanalysis.GetCohortStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetCohortStatsRequest;
  return proto.satys.datanalysis.GetCohortStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetCohortStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest}
 */
proto.satys.datanalysis.GetCohortStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 5:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 8:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addCohorts(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 11:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 12:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetCohortStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetCohortStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCohortsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 1;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 2;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 3;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 4;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated satys.domain.Organisation organisations = 5;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 5));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional satys.domain.Question.Metric metric = 8;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated string cohorts = 9;
 * @return {!Array<string>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getCohortsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setCohortsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addCohorts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearCohortsList = function() {
  return this.setCohortsList([]);
};


/**
 * repeated satys.domain.Label labels = 10;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 11;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 12;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 12));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
*/
proto.satys.datanalysis.GetCohortStatsRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetCohortStatsRequest} returns this
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetCohortStatsRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetCohortStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetCohortStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortStatsList: jspb.Message.toObjectList(msg.getCohortStatsList(),
    proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse}
 */
proto.satys.datanalysis.GetCohortStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetCohortStatsResponse;
  return proto.satys.datanalysis.GetCohortStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse}
 */
proto.satys.datanalysis.GetCohortStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetCohortStatsResponse.CohortStats;
      reader.readMessage(value,proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.deserializeBinaryFromReader);
      msg.addCohortStats(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetCohortStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohort: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetCohortStatsResponse.CohortStats;
  return proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohort(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohort();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string cohort = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.getCohort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} returns this
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.setCohort = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} returns this
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats} returns this
 */
proto.satys.datanalysis.GetCohortStatsResponse.CohortStats.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated CohortStats cohort_stats = 1;
 * @return {!Array<!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats>}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.getCohortStatsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetCohortStatsResponse.CohortStats, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats>} value
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse} returns this
*/
proto.satys.datanalysis.GetCohortStatsResponse.prototype.setCohortStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse.CohortStats}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.addCohortStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetCohortStatsResponse.CohortStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse} returns this
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.clearCohortStatsList = function() {
  return this.setCohortStatsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 2;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 2));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse} returns this
*/
proto.satys.datanalysis.GetCohortStatsResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetCohortStatsResponse} returns this
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetCohortStatsResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetPercentileRankRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetPercentileRankRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPercentileRankRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest}
 */
proto.satys.datanalysis.GetPercentileRankRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetPercentileRankRequest;
  return proto.satys.datanalysis.GetPercentileRankRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetPercentileRankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest}
 */
proto.satys.datanalysis.GetPercentileRankRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetPercentileRankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetPercentileRankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPercentileRankRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date_from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest} returns this
*/
proto.satys.datanalysis.GetPercentileRankRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest} returns this
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest} returns this
*/
proto.satys.datanalysis.GetPercentileRankRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetPercentileRankRequest} returns this
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetPercentileRankRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetPercentileRankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetPercentileRankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetPercentileRankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPercentileRankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentileRank: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetPercentileRankResponse}
 */
proto.satys.datanalysis.GetPercentileRankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetPercentileRankResponse;
  return proto.satys.datanalysis.GetPercentileRankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetPercentileRankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetPercentileRankResponse}
 */
proto.satys.datanalysis.GetPercentileRankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentileRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetPercentileRankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetPercentileRankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetPercentileRankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetPercentileRankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentileRank();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float percentile_rank = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetPercentileRankResponse.prototype.getPercentileRank = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetPercentileRankResponse} returns this
 */
proto.satys.datanalysis.GetPercentileRankResponse.prototype.setPercentileRank = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetHourlyPatternRequest.repeatedFields_ = [1,2,3,4,5,8,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetHourlyPatternRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    satys_domain_domain_pb.Organisation.toObject, includeInstance),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f),
    daysList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    timeRange: (f = msg.getTimeRange()) && proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetHourlyPatternRequest;
  return proto.satys.datanalysis.GetHourlyPatternRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 5:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 8:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 9:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    case 11:
      var values = /** @type {!Array<!proto.satys.generic.DayOfWeek>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDays(values[i]);
      }
      break;
    case 12:
      var value = new proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange;
      reader.readMessage(value,proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetHourlyPatternRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && satys_generic_time_of_day_pb.TimeOfDay.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && satys_generic_time_of_day_pb.TimeOfDay.toObject(includeInstance, f),
    intervalMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange;
  return proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_generic_time_of_day_pb.TimeOfDay;
      reader.readMessage(value,satys_generic_time_of_day_pb.TimeOfDay.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new satys_generic_time_of_day_pb.TimeOfDay;
      reader.readMessage(value,satys_generic_time_of_day_pb.TimeOfDay.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntervalMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_generic_time_of_day_pb.TimeOfDay.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_generic_time_of_day_pb.TimeOfDay.serializeBinaryToWriter
    );
  }
  f = message.getIntervalMinutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional satys.generic.TimeOfDay start = 1;
 * @return {?proto.satys.generic.TimeOfDay}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.getStart = function() {
  return /** @type{?proto.satys.generic.TimeOfDay} */ (
    jspb.Message.getWrapperField(this, satys_generic_time_of_day_pb.TimeOfDay, 1));
};


/**
 * @param {?proto.satys.generic.TimeOfDay|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.generic.TimeOfDay end = 2;
 * @return {?proto.satys.generic.TimeOfDay}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.getEnd = function() {
  return /** @type{?proto.satys.generic.TimeOfDay} */ (
    jspb.Message.getWrapperField(this, satys_generic_time_of_day_pb.TimeOfDay, 2));
};


/**
 * @param {?proto.satys.generic.TimeOfDay|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 interval_minutes = 3;
 * @return {number}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.getIntervalMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange.prototype.setIntervalMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated satys.domain.Organisation organisations = 1;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Organisation, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated satys.domain.Answer answers = 2;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 3;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 4;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 5;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 5));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated satys.domain.Label labels = 8;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 8));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 9;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 10;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 10));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated satys.generic.DayOfWeek days = 11;
 * @return {!Array<!proto.satys.generic.DayOfWeek>}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getDaysList = function() {
  return /** @type {!Array<!proto.satys.generic.DayOfWeek>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.satys.generic.DayOfWeek>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setDaysList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.satys.generic.DayOfWeek} value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.addDays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * optional TimeRange time_range = 12;
 * @return {?proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange, 12));
};


/**
 * @param {?proto.satys.datanalysis.GetHourlyPatternRequest.TimeRange|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
*/
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternRequest} returns this
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetHourlyPatternResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetHourlyPatternResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    intervalTimesList: jspb.Message.toObjectList(msg.getIntervalTimesList(),
    satys_generic_time_of_day_pb.TimeOfDay.toObject, includeInstance),
    gridList: jspb.Message.toObjectList(msg.getGridList(),
    proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetHourlyPatternResponse;
  return proto.satys.datanalysis.GetHourlyPatternResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.satys.generic.DayOfWeek>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDays(values[i]);
      }
      break;
    case 2:
      var value = new satys_generic_time_of_day_pb.TimeOfDay;
      reader.readMessage(value,satys_generic_time_of_day_pb.TimeOfDay.deserializeBinaryFromReader);
      msg.addIntervalTimes(value);
      break;
    case 3:
      var value = new proto.satys.datanalysis.GetHourlyPatternResponse.GridItem;
      reader.readMessage(value,proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.deserializeBinaryFromReader);
      msg.addGrid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetHourlyPatternResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getIntervalTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_generic_time_of_day_pb.TimeOfDay.serializeBinaryToWriter
    );
  }
  f = message.getGridList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: (f = msg.getStart()) && satys_generic_time_of_day_pb.TimeOfDay.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && satys_generic_time_of_day_pb.TimeOfDay.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetHourlyPatternResponse.GridItem;
  return proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 2:
      var value = new satys_generic_time_of_day_pb.TimeOfDay;
      reader.readMessage(value,satys_generic_time_of_day_pb.TimeOfDay.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new satys_generic_time_of_day_pb.TimeOfDay;
      reader.readMessage(value,satys_generic_time_of_day_pb.TimeOfDay.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_generic_time_of_day_pb.TimeOfDay.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      satys_generic_time_of_day_pb.TimeOfDay.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 day = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional satys.generic.TimeOfDay start = 2;
 * @return {?proto.satys.generic.TimeOfDay}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.getStart = function() {
  return /** @type{?proto.satys.generic.TimeOfDay} */ (
    jspb.Message.getWrapperField(this, satys_generic_time_of_day_pb.TimeOfDay, 2));
};


/**
 * @param {?proto.satys.generic.TimeOfDay|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
*/
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional satys.generic.TimeOfDay end = 3;
 * @return {?proto.satys.generic.TimeOfDay}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.getEnd = function() {
  return /** @type{?proto.satys.generic.TimeOfDay} */ (
    jspb.Message.getWrapperField(this, satys_generic_time_of_day_pb.TimeOfDay, 3));
};


/**
 * @param {?proto.satys.generic.TimeOfDay|undefined} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
*/
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float score = 4;
 * @return {number}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 count = 5;
 * @return {number}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.GridItem.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated satys.generic.DayOfWeek days = 1;
 * @return {!Array<!proto.satys.generic.DayOfWeek>}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.getDaysList = function() {
  return /** @type {!Array<!proto.satys.generic.DayOfWeek>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.satys.generic.DayOfWeek>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.setDaysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.satys.generic.DayOfWeek} value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.addDays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * repeated satys.generic.TimeOfDay interval_times = 2;
 * @return {!Array<!proto.satys.generic.TimeOfDay>}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.getIntervalTimesList = function() {
  return /** @type{!Array<!proto.satys.generic.TimeOfDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_generic_time_of_day_pb.TimeOfDay, 2));
};


/**
 * @param {!Array<!proto.satys.generic.TimeOfDay>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
*/
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.setIntervalTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.generic.TimeOfDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.generic.TimeOfDay}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.addIntervalTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.generic.TimeOfDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.clearIntervalTimesList = function() {
  return this.setIntervalTimesList([]);
};


/**
 * repeated GridItem grid = 3;
 * @return {!Array<!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem>}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.getGridList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetHourlyPatternResponse.GridItem, 3));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem>} value
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
*/
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.setGridList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse.GridItem}
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.addGrid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.datanalysis.GetHourlyPatternResponse.GridItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetHourlyPatternResponse} returns this
 */
proto.satys.datanalysis.GetHourlyPatternResponse.prototype.clearGridList = function() {
  return this.setGridList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetThirdPartyOauthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyOauth: (f = msg.getThirdPartyOauth()) && satys_domain_domain_pb.ThirdPartyOauth.toObject(includeInstance, f),
    authorizationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    redirectUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetThirdPartyOauthRequest;
  return proto.satys.datanalysis.SetThirdPartyOauthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ThirdPartyOauth;
      reader.readMessage(value,satys_domain_domain_pb.ThirdPartyOauth.deserializeBinaryFromReader);
      msg.setThirdPartyOauth(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetThirdPartyOauthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyOauth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ThirdPartyOauth.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRedirectUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional satys.domain.ThirdPartyOauth third_party_oauth = 1;
 * @return {?proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.getThirdPartyOauth = function() {
  return /** @type{?proto.satys.domain.ThirdPartyOauth} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ThirdPartyOauth, 1));
};


/**
 * @param {?proto.satys.domain.ThirdPartyOauth|undefined} value
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest} returns this
*/
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.setThirdPartyOauth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest} returns this
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.clearThirdPartyOauth = function() {
  return this.setThirdPartyOauth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.hasThirdPartyOauth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string authorization_code = 2;
 * @return {string}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.getAuthorizationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest} returns this
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.setAuthorizationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string redirect_uri = 3;
 * @return {string}
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.getRedirectUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthRequest} returns this
 */
proto.satys.datanalysis.SetThirdPartyOauthRequest.prototype.setRedirectUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetThirdPartyOauthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthResponse}
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetThirdPartyOauthResponse;
  return proto.satys.datanalysis.SetThirdPartyOauthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetThirdPartyOauthResponse}
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetThirdPartyOauthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetThirdPartyOauthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetThirdPartyOauthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyOauth: (f = msg.getThirdPartyOauth()) && satys_domain_domain_pb.ThirdPartyOauth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthRequest}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetThirdPartyOauthRequest;
  return proto.satys.datanalysis.GetThirdPartyOauthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthRequest}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ThirdPartyOauth;
      reader.readMessage(value,satys_domain_domain_pb.ThirdPartyOauth.deserializeBinaryFromReader);
      msg.setThirdPartyOauth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetThirdPartyOauthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyOauth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ThirdPartyOauth.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ThirdPartyOauth third_party_oauth = 1;
 * @return {?proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.getThirdPartyOauth = function() {
  return /** @type{?proto.satys.domain.ThirdPartyOauth} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ThirdPartyOauth, 1));
};


/**
 * @param {?proto.satys.domain.ThirdPartyOauth|undefined} value
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthRequest} returns this
*/
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.setThirdPartyOauth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthRequest} returns this
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.clearThirdPartyOauth = function() {
  return this.setThirdPartyOauth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetThirdPartyOauthRequest.prototype.hasThirdPartyOauth = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetThirdPartyOauthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyOauth: (f = msg.getThirdPartyOauth()) && satys_domain_domain_pb.ThirdPartyOauth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthResponse}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetThirdPartyOauthResponse;
  return proto.satys.datanalysis.GetThirdPartyOauthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthResponse}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ThirdPartyOauth;
      reader.readMessage(value,satys_domain_domain_pb.ThirdPartyOauth.deserializeBinaryFromReader);
      msg.setThirdPartyOauth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetThirdPartyOauthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyOauth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ThirdPartyOauth.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ThirdPartyOauth third_party_oauth = 1;
 * @return {?proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.getThirdPartyOauth = function() {
  return /** @type{?proto.satys.domain.ThirdPartyOauth} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ThirdPartyOauth, 1));
};


/**
 * @param {?proto.satys.domain.ThirdPartyOauth|undefined} value
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthResponse} returns this
*/
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.setThirdPartyOauth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetThirdPartyOauthResponse} returns this
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.clearThirdPartyOauth = function() {
  return this.setThirdPartyOauth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetThirdPartyOauthResponse.prototype.hasThirdPartyOauth = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTypeformFormsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTypeformFormsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTypeformFormsRequest}
 */
proto.satys.datanalysis.GetTypeformFormsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTypeformFormsRequest;
  return proto.satys.datanalysis.GetTypeformFormsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTypeformFormsRequest}
 */
proto.satys.datanalysis.GetTypeformFormsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTypeformFormsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTypeformFormsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetTypeformFormsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTypeformFormsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    formsList: jspb.Message.toObjectList(msg.getFormsList(),
    proto.satys.datanalysis.GetTypeformFormsResponse.Form.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTypeformFormsResponse;
  return proto.satys.datanalysis.GetTypeformFormsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.datanalysis.GetTypeformFormsResponse.Form;
      reader.readMessage(value,proto.satys.datanalysis.GetTypeformFormsResponse.Form.deserializeBinaryFromReader);
      msg.addForms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTypeformFormsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.datanalysis.GetTypeformFormsResponse.Form.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetTypeformFormsResponse.Form.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse.Form} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse.Form}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetTypeformFormsResponse.Form;
  return proto.satys.datanalysis.GetTypeformFormsResponse.Form.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse.Form} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse.Form}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetTypeformFormsResponse.Form.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse.Form} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse.Form} returns this
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse.Form} returns this
 */
proto.satys.datanalysis.GetTypeformFormsResponse.Form.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Form forms = 1;
 * @return {!Array<!proto.satys.datanalysis.GetTypeformFormsResponse.Form>}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.getFormsList = function() {
  return /** @type{!Array<!proto.satys.datanalysis.GetTypeformFormsResponse.Form>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.datanalysis.GetTypeformFormsResponse.Form, 1));
};


/**
 * @param {!Array<!proto.satys.datanalysis.GetTypeformFormsResponse.Form>} value
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse} returns this
*/
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.setFormsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.datanalysis.GetTypeformFormsResponse.Form=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse.Form}
 */
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.addForms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.datanalysis.GetTypeformFormsResponse.Form, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetTypeformFormsResponse} returns this
 */
proto.satys.datanalysis.GetTypeformFormsResponse.prototype.clearFormsList = function() {
  return this.setFormsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && satys_domain_domain_pb.Label.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateLabelRequest}
 */
proto.satys.datanalysis.CreateLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateLabelRequest;
  return proto.satys.datanalysis.CreateLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateLabelRequest}
 */
proto.satys.datanalysis.CreateLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Label label = 1;
 * @return {?proto.satys.domain.Label}
 */
proto.satys.datanalysis.CreateLabelRequest.prototype.getLabel = function() {
  return /** @type{?proto.satys.domain.Label} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Label, 1));
};


/**
 * @param {?proto.satys.domain.Label|undefined} value
 * @return {!proto.satys.datanalysis.CreateLabelRequest} returns this
*/
proto.satys.datanalysis.CreateLabelRequest.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.CreateLabelRequest} returns this
 */
proto.satys.datanalysis.CreateLabelRequest.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.CreateLabelRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.CreateLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.CreateLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.CreateLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.CreateLabelResponse}
 */
proto.satys.datanalysis.CreateLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.CreateLabelResponse;
  return proto.satys.datanalysis.CreateLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.CreateLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.CreateLabelResponse}
 */
proto.satys.datanalysis.CreateLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.CreateLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.CreateLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.CreateLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.CreateLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.datanalysis.AssignLabelRequest.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.satys.datanalysis.AssignLabelRequest.EntityCase = {
  ENTITY_NOT_SET: 0,
  ORGANISATION: 2,
  QUESTIONNAIRE: 3,
  MEASUREMENT: 4,
  QUESTION: 5
};

/**
 * @return {proto.satys.datanalysis.AssignLabelRequest.EntityCase}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getEntityCase = function() {
  return /** @type {proto.satys.datanalysis.AssignLabelRequest.EntityCase} */(jspb.Message.computeOneofCase(this, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.AssignLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.AssignLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.AssignLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && satys_domain_domain_pb.Label.toObject(includeInstance, f),
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f),
    measurement: (f = msg.getMeasurement()) && satys_domain_domain_pb.Measurement.toObject(includeInstance, f),
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.AssignLabelRequest}
 */
proto.satys.datanalysis.AssignLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.AssignLabelRequest;
  return proto.satys.datanalysis.AssignLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.AssignLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.AssignLabelRequest}
 */
proto.satys.datanalysis.AssignLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.setMeasurement(value);
      break;
    case 5:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.AssignLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.AssignLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.AssignLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Label label = 1;
 * @return {?proto.satys.domain.Label}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getLabel = function() {
  return /** @type{?proto.satys.domain.Label} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Label, 1));
};


/**
 * @param {?proto.satys.domain.Label|undefined} value
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
*/
proto.satys.datanalysis.AssignLabelRequest.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Organisation organisation = 2;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
*/
proto.satys.datanalysis.AssignLabelRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional satys.domain.Questionnaire questionnaire = 3;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 3));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
*/
proto.satys.datanalysis.AssignLabelRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional satys.domain.Measurement measurement = 4;
 * @return {?proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getMeasurement = function() {
  return /** @type{?proto.satys.domain.Measurement} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Measurement, 4));
};


/**
 * @param {?proto.satys.domain.Measurement|undefined} value
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
*/
proto.satys.datanalysis.AssignLabelRequest.prototype.setMeasurement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.clearMeasurement = function() {
  return this.setMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.hasMeasurement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional satys.domain.Question question = 5;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 5));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
*/
proto.satys.datanalysis.AssignLabelRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.satys.datanalysis.AssignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.AssignLabelRequest} returns this
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.AssignLabelRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.AssignLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.AssignLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.AssignLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.AssignLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.AssignLabelResponse}
 */
proto.satys.datanalysis.AssignLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.AssignLabelResponse;
  return proto.satys.datanalysis.AssignLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.AssignLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.AssignLabelResponse}
 */
proto.satys.datanalysis.AssignLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.AssignLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.AssignLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.AssignLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.AssignLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.satys.datanalysis.UnassignLabelRequest.EntityCase = {
  ENTITY_NOT_SET: 0,
  ORGANISATION: 2,
  QUESTIONNAIRE: 3,
  MEASUREMENT: 4,
  QUESTION: 5
};

/**
 * @return {proto.satys.datanalysis.UnassignLabelRequest.EntityCase}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getEntityCase = function() {
  return /** @type {proto.satys.datanalysis.UnassignLabelRequest.EntityCase} */(jspb.Message.computeOneofCase(this, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnassignLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnassignLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnassignLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && satys_domain_domain_pb.Label.toObject(includeInstance, f),
    organisation: (f = msg.getOrganisation()) && satys_domain_domain_pb.Organisation.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && satys_domain_domain_pb.Questionnaire.toObject(includeInstance, f),
    measurement: (f = msg.getMeasurement()) && satys_domain_domain_pb.Measurement.toObject(includeInstance, f),
    question: (f = msg.getQuestion()) && satys_domain_domain_pb.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest}
 */
proto.satys.datanalysis.UnassignLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnassignLabelRequest;
  return proto.satys.datanalysis.UnassignLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnassignLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest}
 */
proto.satys.datanalysis.UnassignLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Organisation;
      reader.readMessage(value,satys_domain_domain_pb.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.setMeasurement(value);
      break;
    case 5:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnassignLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnassignLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnassignLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Label label = 1;
 * @return {?proto.satys.domain.Label}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getLabel = function() {
  return /** @type{?proto.satys.domain.Label} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Label, 1));
};


/**
 * @param {?proto.satys.domain.Label|undefined} value
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
*/
proto.satys.datanalysis.UnassignLabelRequest.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional satys.domain.Organisation organisation = 2;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Organisation, 2));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
*/
proto.satys.datanalysis.UnassignLabelRequest.prototype.setOrganisation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional satys.domain.Questionnaire questionnaire = 3;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Questionnaire, 3));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
*/
proto.satys.datanalysis.UnassignLabelRequest.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional satys.domain.Measurement measurement = 4;
 * @return {?proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getMeasurement = function() {
  return /** @type{?proto.satys.domain.Measurement} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Measurement, 4));
};


/**
 * @param {?proto.satys.domain.Measurement|undefined} value
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
*/
proto.satys.datanalysis.UnassignLabelRequest.prototype.setMeasurement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.clearMeasurement = function() {
  return this.setMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.hasMeasurement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional satys.domain.Question question = 5;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Question, 5));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
*/
proto.satys.datanalysis.UnassignLabelRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.satys.datanalysis.UnassignLabelRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.UnassignLabelRequest} returns this
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.UnassignLabelRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.UnassignLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.UnassignLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.UnassignLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnassignLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.UnassignLabelResponse}
 */
proto.satys.datanalysis.UnassignLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.UnassignLabelResponse;
  return proto.satys.datanalysis.UnassignLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.UnassignLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.UnassignLabelResponse}
 */
proto.satys.datanalysis.UnassignLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.UnassignLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.UnassignLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.UnassignLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.UnassignLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetLabelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetLabelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetLabelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scope: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetLabelsRequest}
 */
proto.satys.datanalysis.GetLabelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetLabelsRequest;
  return proto.satys.datanalysis.GetLabelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetLabelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetLabelsRequest}
 */
proto.satys.datanalysis.GetLabelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetLabelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetLabelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetLabelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scope = 1;
 * @return {string}
 */
proto.satys.datanalysis.GetLabelsRequest.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.datanalysis.GetLabelsRequest} returns this
 */
proto.satys.datanalysis.GetLabelsRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetLabelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetLabelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetLabelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetLabelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetLabelsResponse}
 */
proto.satys.datanalysis.GetLabelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetLabelsResponse;
  return proto.satys.datanalysis.GetLabelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetLabelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetLabelsResponse}
 */
proto.satys.datanalysis.GetLabelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetLabelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetLabelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetLabelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetLabelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Label labels = 1;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetLabelsResponse.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetLabelsResponse} returns this
*/
proto.satys.datanalysis.GetLabelsResponse.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetLabelsResponse.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetLabelsResponse} returns this
 */
proto.satys.datanalysis.GetLabelsResponse.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetMyPerformanceIndicatorRequest;
  return proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 1;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 1));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} returns this
*/
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} returns this
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorRequest.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    performanceIndicator: (f = msg.getPerformanceIndicator()) && satys_domain_domain_pb.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.SetMyPerformanceIndicatorResponse;
  return proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.PerformanceIndicator performance_indicator = 1;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 1));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse} returns this
*/
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse} returns this
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest;
  return proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    performanceIndicatorsList: jspb.Message.toObjectList(msg.getPerformanceIndicatorsList(),
    satys_domain_domain_pb.PerformanceIndicator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse;
  return proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.PerformanceIndicator;
      reader.readMessage(value,satys_domain_domain_pb.PerformanceIndicator.deserializeBinaryFromReader);
      msg.addPerformanceIndicators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformanceIndicatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.PerformanceIndicator performance_indicators = 1;
 * @return {!Array<!proto.satys.domain.PerformanceIndicator>}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.getPerformanceIndicatorsList = function() {
  return /** @type{!Array<!proto.satys.domain.PerformanceIndicator>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.PerformanceIndicator, 1));
};


/**
 * @param {!Array<!proto.satys.domain.PerformanceIndicator>} value
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse} returns this
*/
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.setPerformanceIndicatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.PerformanceIndicator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PerformanceIndicator}
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.addPerformanceIndicators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.PerformanceIndicator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse} returns this
 */
proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.prototype.clearPerformanceIndicatorsList = function() {
  return this.setPerformanceIndicatorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetActiveChannelsRequest.repeatedFields_ = [1,2,3,4,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetActiveChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetActiveChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    satys_domain_domain_pb.Answer.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    satys_domain_domain_pb.Question.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    satys_domain_domain_pb.Questionnaire.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    satys_domain_domain_pb.Measurement.toObject, includeInstance),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    satys_domain_domain_pb.Label.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetActiveChannelsRequest;
  return proto.satys.datanalysis.GetActiveChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Answer;
      reader.readMessage(value,satys_domain_domain_pb.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Question;
      reader.readMessage(value,satys_domain_domain_pb.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 3:
      var value = new satys_domain_domain_pb.Questionnaire;
      reader.readMessage(value,satys_domain_domain_pb.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 4:
      var value = new satys_domain_domain_pb.Measurement;
      reader.readMessage(value,satys_domain_domain_pb.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 9:
      var value = new satys_domain_domain_pb.Label;
      reader.readMessage(value,satys_domain_domain_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 10:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetActiveChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetActiveChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.Answer.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Question.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      satys_domain_domain_pb.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      satys_domain_domain_pb.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      satys_domain_domain_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.Answer answers = 1;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Answer, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated satys.domain.Question questions = 2;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Question, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated satys.domain.Questionnaire questionnaires = 3;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Questionnaire, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated satys.domain.Measurement measurements = 4;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Measurement, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional google.protobuf.Timestamp date_from = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated satys.domain.Label labels = 9;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Label, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated satys.domain.Channel channels = 10;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
*/
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsRequest} returns this
 */
proto.satys.datanalysis.GetActiveChannelsRequest.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.datanalysis.GetActiveChannelsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.datanalysis.GetActiveChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.datanalysis.GetActiveChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetActiveChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    satys_domain_domain_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.datanalysis.GetActiveChannelsResponse}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.datanalysis.GetActiveChannelsResponse;
  return proto.satys.datanalysis.GetActiveChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.datanalysis.GetActiveChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.datanalysis.GetActiveChannelsResponse}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.Channel;
      reader.readMessage(value,satys_domain_domain_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.datanalysis.GetActiveChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.datanalysis.GetActiveChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.datanalysis.GetActiveChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      satys_domain_domain_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsResponse} returns this
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated satys.domain.Channel channels = 2;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.Channel, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.datanalysis.GetActiveChannelsResponse} returns this
*/
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.datanalysis.GetActiveChannelsResponse} returns this
 */
proto.satys.datanalysis.GetActiveChannelsResponse.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


goog.object.extend(exports, proto.satys.datanalysis);
